import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';

import agredada_masiva from '../../../../assets/img/user/agregada_masiva.png'
import { users_actions } from "../../../../store/actions/users";

import { nationalocupations } from '../../../../store/constants/nationalocupations.js';

export default function UserCsv({showModal,dataCsv,setShowModal,onCloseSubMenu}) {
  
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [result, setResult] = useState([]);
  const [tableHead, setTableHead] =  useState({});

  const users_cvs = useSelector(state => state.users.error_users_create);

    useEffect(() => {
        //var csvcounter = 0;
        //var errorFound = [];

        if(dataCsv.length > 0){
            
            setData(dataCsv);
            setTableHead(Object.keys(dataCsv[0]));

            //for (; csvcounter < dataCsv.length; csvcounter++) {
              //if ((dataCsv[csvcounter].nationalocupation == '') || (typeof (nationalocupations[dataCsv[csvcounter].nationalocupation]) === 'undefined')) {
                //errorFound.push({ message: 'Ocupación no encontrada', row: (csvcounter + 1), column: 'nationalocupation' });
              //}
            //}

        }

    },[dataCsv]);

    const handleChanger = (index,oneKey,newValue) => {
        const updatedArray = [...data];
        updatedArray[index][oneKey] = newValue;
        setData(updatedArray);
    }

    const onSubmit = async () => {

      var l_ModalUploading = Swal.fire({
        title: 'Cargando usuarios...',
        timerProgressBar: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
        onOpen: () => Swal.showLoading()
      });

      data.map((item, index) => {
        if (typeof(nationalocupations[item.nationalocupation]) !== 'undefined') {
          item.nationalocupation = nationalocupations[item.nationalocupation].num;
        }

        item.area = item.employment;
      });
 
      let result = await users_actions.users_add_csv(data);

      console.log('==============');
      console.log(result);

      l_ModalUploading.close();

      if ((typeof(result.aggregates)=='undefined') && (typeof(result.message)!= 'undefined')) {
        Swal.fire(
          'Ocurrio un error al subir los usuarios',
          result.message,
          'error'
        );
      } else {

        let added = result.aggregates > 0 ? `<h4>Usuario Agregados: ${result.aggregates}</h4>` :  ``;
        let update = result.updated > 0 ? `<h4>Usuario Actualizados: ${result.updated}</h4>` :  ``;
        let warinings = result.warinings > 0 ? `<h4>Usuario con errores: ${result.warinings}</h4>` :  ``;
        let datealles = '';
        result.warining.map(item=>{
          datealles+= `${item[0].mensaje}<br>`;
        });
        setShowModal(false)
        Swal.fire(
          'Carga Masiva de Usuarios',
          `${added}<br>
            ${update}<br>
            ${warinings}<br><br>
            ${datealles}`,
          'warinig',
        )
        setData([])

        setResult(result)
      }
    }

  return (
    <>
      {showModal ? (
        <>
        <div className="modal-csv fixed z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="justify-center items-center flex">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                  { result > 0 ? `Usuarios en total ${result.aggregates}` : `Total de usuarios ${dataCsv.length }` }
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                  </button>
                </div>
                {/*body*/}
                <form className="relative p-6 flex-auto max-h-72">
                    { data.length > 0 ? 
                        <div className="h-36 scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-gray-300 overflow-auto">
                            <table className="table-auto border-collapse">
                                <thead><tr className="bg-blue-500 bg-opacity-10" >{ tableHead.map(head => head != 'error' ? <th className="border border-blue-500"> { head.toUpperCase() } </th> : '' ) }</tr></thead>
                                <tbody>
                                    { data.map((item, index) => (
                                        <tr className="bg-blue-500 bg-opacity-5" key={index + 2}>
                                            { Object.keys((item)).map((oneKey,i)=>(
                                              oneKey != 'error' ?
                                              <td className="border border-blue-500 p-0.5" key={i}> 
                                                { /* input type={ tableHead[i] == 'email' ? 'email' : 'text' } name={tableHead[i]} onChange={ e => handleChanger(index, tableHead[i], e.target.value ) } value={item[tableHead[i]]}/> */ }
                                                <span className="bg-white border-2 border-gray-400 px-2 py-1 inline-block w-full h-full whitespace-nowrap overflow-x-hidden text-clip">
                                                  {
                                                    (() => {
                                                      var l_dateObject;
                                                      if ((tableHead[i] == 'fechainicio') || (tableHead[i] == 'fechavigencia') || (tableHead[i] == 'dateadmission')) {
                                                        //console.log(item[tableHead[i]]);
                                                        l_dateObject = new Date(item[tableHead[i]] * 1000);
                                                        //console.log(l_dateObject);
                                                        return (l_dateObject.toLocaleDateString());
                                                        //} else if ( (tableHead[i]=='fideicomisonum') && (typeof(numerosfideicomisos[item[tableHead[i]]])!=='undefined') ) {
                                                        //return numerosfideicomisos[item[tableHead[i]]];
                                                        //} else if ( (tableHead[i]=='comitenum') && (typeof(comitenums[item[tableHead[i]]])!=='undefined') ) {
                                                        //return comitenums[item[tableHead[i]]];
                                                      } else if ((tableHead[i] == 'nationalocupation') && (typeof (nationalocupations[item[tableHead[i]]]) !== 'undefined')) {
                                                        return nationalocupations[item[tableHead[i]]].name.substring(0, 20);
                                                        //} else if ((tableHead[i]=='employment') && (typeof(adscritos[item[tableHead[i]]])!=='undefined') ) {
                                                        //  return adscritos[item[tableHead[i]]];
                                                      } else {
                                                        return item[tableHead[i]];
                                                      }
                                                    })()
                                                  }
                                                </span>
                                              </td>
                                              : ''
                                            )) }
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                        </div>
                    :
                    <div>
                      { users_cvs.map(item => (
                        <ul>
                          <li>{ item.firstname } { item.lastname }, { item.email }</li>
                          <p className="text-red-500">{ item.error }</p>
                          <br/>
                        </ul>
                      )) }
                    </div>  
                  } 
                </form>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                { data.length > 0 ?
                  <div>
                    <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>
                      Cerrar
                    </button> 
                    <button className="bg-emerald-500 text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => onSubmit(false)}>
                      Agregar
                    </button>
                  </div>
                : <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>
                    Cerrar
                  </button> }
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="opacity-25 w-full fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}