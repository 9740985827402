import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { badges_actions } from '../../../../store/actions/badges';
import defaultisingnia from '../../../../assets/img/insignias/insignia.png';

// const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

const AgregarInsignia = ({ onCloseSubMenu }) => {
    const [fileUrl, setFileUrl] = useState(defaultisingnia);

    const dispatch = useDispatch();

    const validate_add = Yup.object().shape({
        name: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(16, 'Debe ser menor a 16 caracteres')
            .required('El campo es requerido'),
        description: Yup.string()
            .min(5, 'Debe ser mayor a 5 caracter')
            .required('El campo es requerido'),
        file: Yup.mixed()
            .required("El campo es requerido")
            .test(
                "fileSize",
                `El archivo es demasiado grande`,
                value => {
                    return value && value.size <= 2621440
                }
            )
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            file: '',
            usercreated: 6,
        },
        validationSchema: validate_add,
        onSubmit: values => {
            //console.log(values);
            handleSubmit(values)
        },
    });

    const handleSubmit = async (badge) => {
        await badges_actions.create_badge(badge);
        onCloseSubMenu(false);
        dispatch(badges_actions.get_all_badges());
    }
    function processImage(event) {
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
    }

    return (
        <form onSubmit={formik.handleSubmit} className="formularios">
            <div className="flex cursor-pointer items-center text-md mb-4 relative group">
                <div className="w-2/5">
                    <img className="h-20 w-20 rounded-full overflow-hidden" src={fileUrl} alt=""></img>
                </div>
                <div className="flex items-center text-md relative group ml-1">
                    <label className="mr-1  group-hover:text-opacity-50" htmlFor="">Imagen para la insignia</label>
                    <p className="text-3xl  group-hover:text-opacity-50">+</p>
                    <input className="rounded-md" onChange={e => {
                        processImage(e);
                        formik.setFieldValue('file', e.target.files[0])
                    }} name="file" type="file" className="absolute top-0 left-0 opacity-0" />
                    {/* <p className="text-red-600 text-sm">{formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</p> */}
                </div>
            </div>
            <p className="text-red-600 text-sm mb-6">{formik.errors.file ? <div>{formik.errors.file}</div> : null}</p>
            <div className="campo">
                <label className="text-md" htmlFor="">Nombre de la Insignia</label>
                <input className="rounded-md" onChange={formik.handleChange} type="text" name="name" value={formik.values.name} placeholder="Nombre de la Insignia" />
                <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Descripción de la Insignia</label>
                <textarea className="rounded-md" onChange={formik.handleChange} type="text" name="description" placeholder="Descripción" value={formik.values.description} />
                <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
            </div>
            <button className="block mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48 hover:bg-opacity-100">Guardar</button>
        </form>
    );
}

export default AgregarInsignia;