import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { messages } from '../../store/helpers/calendar-massages-es';

import { CalendarEvent } from '../../Components/Calendar/CalendarEvent';
import { cohorts_actions } from '../../store/actions/cohorts';
import { meetings_actions } from '../../store/actions/meetings';
import { courses_actions } from '../../store/actions/courses';
import AgregarEvento from './AgregarEvento';
import EditarEvento from './EditarEvento';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

moment.locale('es');

const localizer = momentLocalizer(moment);

const formats = {
    weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
};


const Inicio = () => {
    const dispatch = useDispatch();
    const arrayEvents = useSelector(state => state.calendar.events);    
    let user = JSON.parse(localStorage.getItem('user'));

    var events = arrayEvents.map((value) => {
        // let link = '';
        // if (value.tipo === 'course') {
        //     link = 'https://universidadiac.com.mx/#/curso/' + value.idtipo;
        // }
        // else {
        //     link = value.linktipo;
        // }
        
        return {
            id: value.id,
            title: value.title,
            start: new Date(value.start),
            end: new Date(value.end),
            notes: value.notes,
            area: value.area,
            namearea: value.namearea,
            bgcolor: value.bgcolor,
            tipo: value.tipo,
            idtipo: value.idtipo,
            mode: value.mode,
            // linktipo: link,
            user: {
                _id: value.user._id,
                name: value.user.name
            },
            fideicomiso: value.fideicomiso
        }
    })

    useEffect(() => {
        dispatch(cohorts_actions.get_all_cohorts());
        dispatch(meetings_actions.get_all_meetings());
        dispatch(courses_actions.get_all_courses());
    }, [])

    const [openMenu, setOpenMenu] = useState(false);
    const [openMenuEdit, setOpenMenuEdit] = useState({
        open: false,
        event: {}
    });

    const [lastView, setLastView] = useState(localStorage.getItem('lastView') || 'month');

    const clickMenuAdd = () => {
        setOpenMenu(true);
    }

    const onViewChange = (e) => {
        setLastView(e);
        localStorage.setItem('lastView', e);
    }

    const onDoubleClick = (evento) => {
        setOpenMenuEdit({
            open: true,
            event: evento
        });
        dispatch(cohorts_actions.get_all_cohorts());
        dispatch(meetings_actions.get_all_meetings());
        dispatch(courses_actions.get_all_courses());
    }

    /* Este se llama cuando se dá un sólo click */
    // const onSelectEvent = (e) => {
    //     //console.log(e);
    // }

    // /* Detecta si se da click fuera del evento y lo desactiva poniendo el activeEvent =null para ocultar el botón de delete */
    // const onSelectSlot = (e) => {
    //     //dispatch(eventClearActiveEvent());
    // }
    /* Toma los parámetros del calendar como son event, start, end, isSelected para mofificarlos en este caso se aplica un estilo propio general */
    const eventStyleGetter = (event, start, end, isSelected) => {
        const style = {
            backgroundColor: event.bgcolor,
            borderRadius: '0px',
            opacity: 0.8,
            display: 'block',
            color: 'white'
        }
        return {
            style
        }
    }
    return (
        <div className="bg-white text-blue-500 m-5 p-5 border border-gray-500 rounded-2xl w-full overflow-y-scroll home">
            <div className="flex justify-between w-full mb-4">
                <div className="w-1/2">
                    <p className="mb-2">Hoy es <Moment className="capitalize" format="DD MMMM"></Moment> </p>
                    <h3 className="font-black text-4xl">Planeador anual <Moment className="capitalize" format="YYYY"></Moment></h3>
                </div>
                {
                    user.istutor ? 
                    null
                    :
                        /*<div className="flex items-start justify-between">
                            <button onClick={() => clickMenuAdd(!openMenu)} className="px-2 py-2 bg-blue-500 text-white rounded-xl w-36">
                                Agregar evento
                            </button>
                        </div>*/null
                }
                {openMenu ? <AgregarEvento openMenuAdd={setOpenMenu} /> : null}

                {openMenuEdit.open ?
                    <div>
                        <div className="fixed h-screen z-10 min-w-full top-0 left-0 bg-black bg-opacity-50 overflow-hidden"></div>

                        <div className="bg-gray-100 text-black absolute z-10 top-2/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-1 px-5 py-5 rounded-xl h-3/4 overflow-auto">
                            <div className=" text-3xl flex justify-between mb-5">
                                <h3>Editar evento</h3>
                                <div className="flex"
                                    onClick={() => setOpenMenuEdit(false)}>
                                    <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '0px', marginLeft: '-0.3rem'}} />
                                </div>
                            </div>

                            <div className="flex justify-between">
                                <EditarEvento openMenu={openMenuEdit} closeMenuEdit={setOpenMenuEdit} />
                            </div>
                        </div>
                    </div>
                    : null
                }

            </div>
            {/* <div className="flex justify-between w-full">
                <div className="flex flex-col w-auto">
                    <div> <p>Lista de usuarios</p> </div>
                    <div className="mr-4 py-2">
                        <ListCicleAvatar />
                    </div>
                </div>
            </div> */}

            {/* <div className="w-full mb-5">
                <p className="mb-2">Todas las áreas</p>
                <div className="w-3/12">
                    <Select
                        options={opciones}
                        name="area"
                        onChange={(value) => onChange(value)}
                    />
                </div>
            </div> */}

            <div className="flex">
                <div className="w-full">
                    <Calendar
                        showMultiDayTimes
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        messages={messages}
                        /* tanto onSelectSlot como slectable de necesita para Detecta si se da click fuera del evento y lo desactiva poniendo el activeEvent =null para ocultar el botón de delete */
                        //onSelectSlot={clickMenuAdd}
                        //onSelectEvent={onDoubleClick}
                        /* Obtiene el estilo definido para cambiar el aspecto del calendar */
                        eventPropGetter={eventStyleGetter}
                        onDoubleClickEvent={onDoubleClick}
                        onView={onViewChange}
                        selectable={true}
                        /* Se especifica que la vista que va tener cuando ingresemos a la página es el lastView */
                        view={lastView}
                        formats={formats}
                        components={{
                            /* Recive CalendarEvent.js como referencia */
                            /* En CalendarEvent se especifica lo que va a mostrar el recuadro de la tarea que se ubica en los diferentes días */
                            event: CalendarEvent
                        }}
                        style={{ height: '100vh', marginTop: '20px', marginBottom: '50px' }}
                    />
                </div>
            </div>
        </div>
    );
}


export default Inicio;