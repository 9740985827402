import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import pocari from '../../assets/img/pocari.png';
import dafi from '../../assets/img/dafi.png';
import notificaciones from '../../assets/img/notificaciones.png';
import notificacionesvacio from '../../assets/img/notificacionesvacio.png';
import { notifications_actions } from '../../store/actions/notifications';
import Notificaciones from './Notificaciones';
import {userAuthActions} from '../..//store/actions/user_auth';
import { user } from '../../config/user_token';

const Nav = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const [close, setClose] = useState(false);
    const [notis, setNotis] = useState([]);
    const [init, setInit] = useState(false);
    const dispatch = useDispatch();

    const onclose = () => {
        setClose(!close);
    }

    const refresh_notifications = () => {
        notifications_actions.get_user_notifications().then(res => {

            res.request_notifications.then(response => {

                if (response.statusText === 'OK') {
                    setNotis(response.data);
                } else {
                    setNotis([]);
                }

            });

        });
    }

    //const notis = [{id: 1, subject: 'notificación 1', fulltexthtml: 'Notificación que notifica...'}, {id: 2, subject: 'notificación 2', fulltexthtml: 'Otra notificación, también notifica'}];
    if (!init) {
        refresh_notifications();
        setInit(true);
    }

    return (
        <div className="relative">
            <nav className="flex flex-wrap items-center justify-between py-4 px-8 bg-blue-iac text-white w-full">
                <div className="flex w-8/12 items-center">
                    <div className="w-3/12"> <img src={pocari} alt="" /> </div>
                    <div className="inline-flex w-5/12"> 
                        <p>Capacitación POCARI</p>
                        {user.istutor ?  
                            <p className="ml-8">TUTOR</p>:
                            <p className="ml-8">ADMIN</p>
                        }
                    </div>
                </div>

                <div className="flex text-center items-center w-4/12">
                    {/*<div onClick={onclose} className="flex justify-center w-24 cursor-pointer"> <img src={ notis.length ? notificaciones : notificacionesvacio } alt="" /> </div>*/}
                    {/*<div className="border-l h-12"></div>*/}
                    {user.istutor ? 
                        <div className="flex justify-center text-md w-44"> <p>Tutor {user.firstname}</p> </div>
                        :
                        <div className="flex justify-center text-md w-44"> <p>Admin {user.firstname}</p> </div>
                    }
                    <div className="flex justify-end items-center w-5/12">
                        <div className="flex justify-center items-center border-white border rounded-full w-10 h-10">  <p className="text-gray-500">AD</p> </div>
                    </div>
                    <div className="flex justify-end text-md w-4/12 cursor-pointer" onClick={ ()=>{ dispatch(userAuthActions.logout());}}> <p>Cerrar sesión</p> </div>
                </div>
                {/* <button className="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700">
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button> */}
            </nav>
            {close ? <Notificaciones onclose={onclose} notis={notis} /> : null}

        </div>
    );
}

export default Nav;