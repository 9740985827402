
import { cohorts_constants } from '../constants/cohorts';
import { cohorts_service } from '../services/cohorts';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const cohorts_actions = {
    get_all_cohorts,
    cohorts_edit,
    cohorts_delete,
    cohorts_create,
    add_members_cohort,
    remove_members_cohort,
    cohorts_members_users
};

function get_all_cohorts() {
    return dispatch => {
        dispatch(request());

        cohorts_service.get_all_cohorts()
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.categories));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: cohorts_constants.COHORTS_REQUEST, payload: true } }
    function success(param) { return { type: cohorts_constants.COHORTS_SUCCESS, payload: param } }
    function failure(error) { return { type: cohorts_constants.COHORTS_FAILURE, payload: error } }
}

async function cohorts_edit(cohort, cohort_id) {

    await cohorts_service.cohorts_edit(cohort)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString());
                    return Swal.fire(
                        'Grupo',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Grupo',
                        'Fue actualizado con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: cohorts_constants.COHORTS_EDIT_REQUEST, payload: true } }
    function success(param) { return { type: cohorts_constants.COHORTS_EDIT_REQUEST, payload: param } }
    function failure(error) { return { type: cohorts_constants.COHORTS_EDIT_REQUEST, payload: error } }
}


async function cohorts_delete(cohort) {

        await cohorts_service.cohorts_delete(cohort)
            .then(
                res => {
                    if (res == 0) {
                        failure(res);
                        return Swal.fire(
                            'Grupo',
                            'Ocurrio un error, intenta más tarde',
                            'error',
                        )
                    } else {
                        success(res);
                        return Swal.fire(
                            'Grupo',
                            'Fue borrado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    failure(error.toString());
                    alert_actions.error(error.toString());
                    return Swal.fire(
                        'Error',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => failure(e.toString()))

    function request() { return { type: cohorts_constants.COHORTS_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: cohorts_constants.COHORTS_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: cohorts_constants.COHORTS_DELETE_FAILURE, payload: error } }
}

async function cohorts_create(cohort) {

        await cohorts_service.cohorts_create(cohort)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        failure(res.error.message.toString());
                        return Swal.fire(
                            'Grupo',
                            `${res.error.message}`,
                            'error',
                        )
                    } else {
                        success(res);
                        return Swal.fire(
                            'Grupo',
                            'Fue agregado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    failure(error.toString());
                    alert_actions.error(error.toString());
                    return Swal.fire(
                        'Error',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => failure(e.toString()))

    function request() { return { type: cohorts_constants.COHORTS_CREATE_REQUEST, payload: true } }
    function success(param) { return { type: cohorts_constants.COHORTS_CREATE_SUCCESS, payload: param } }
    function failure(error) { return { type: cohorts_constants.COHORTS_CREATE_FAILURE, payload: error } }
}

async function add_members_cohort(cohort, users) {
    await cohorts_service.add_members_cohort(cohort, users)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString())
                    //dispatch();
                    return Swal.fire(
                        'Grupo',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    success(res)
                    //dispatch(success(res));
                    return Swal.fire(
                        'Grupo',
                        'El/los usuarios fueron agregados con exitos',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString())
                //dispatch(failure(error.toString()));
                alert_actions.error(error.toString());
                //dispatch(alert_actions.error(error.toString()));
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            failure(e.toString());
            //dispatch(failure(e.toString()));
        })
    /* return dispatch=>{
        dispatch( request() );
    } */
    function request() { return { type: cohorts_constants.ADD_MEMBERS_COHORTS_REQUEST, payload: true } }
    function success(param) { return { type: cohorts_constants.ADD_MEMBERS_COHORTS_SUCCESS, payload: param } }
    function failure(error) { return { type: cohorts_constants.ADD_MEMBERS_COHORTS_FAILURE, payload: error } }
}

async function remove_members_cohort(cohort, users) {
    await cohorts_service.remove_members_cohort(cohort, users)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    //dispatch(failure(res.error.message.toString()));
                    return Swal.fire(
                        'Grupo',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    //dispatch(success(res));
                    return Swal.fire(
                        'Grupo',
                        'Los usuarios seleccionados fueron excluidos con exito.',
                        'success'
                    )
                }
            },
            error => {
                //dispatch(failure(error.toString()));
                //dispatch(alert_actions.error(error.toString()));
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return e.toString();
            //dispatch(failure(e.toString()))
        })
    return dispatch => {
        dispatch(request());

    }
    function request() { return { type: cohorts_constants.REMOVE_MEMBERS_COHORTS_REQUEST, payload: true } }
    function success(param) { return { type: cohorts_constants.REMOVE_MEMBERS_COHORTS_SUCCESS, payload: param } }
    function failure(error) { return { type: cohorts_constants.REMOVE_MEMBERS_COHORTS_FAILURE, payload: error } }
}

async function cohorts_members_users(cohortid) {

    return await cohorts_service.cohorts_members_users(cohortid)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString())
                    //dispatch(failure(res.error.message.toString()));
                } else {

                    return res.members_users

                    //dispatch(success(res.categories));
                }
            },
            error => {
                failure(error.toString())
                alert_actions.error(error.toString())
                //dispatch(failure(error.toString()));
                //dispatch(alert_actions.error(error.toString()));
            }
        ).catch(e => {
            failure(e.toString())

            //dispatch(failure(e.toString()))
        })
    /* return dispatch => {
        dispatch( request() );
        
    }; */

    function request() { return { type: cohorts_constants.COHORTS_MEMBERS_REQUEST, payload: true } }
    function success(param) { return { type: cohorts_constants.COHORTS_MEMBERS_SUCCESS, payload: param } }
    function failure(error) { return { type: cohorts_constants.COHORTS_MEMBERS_FAILURE, payload: error } }
}