import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'

import { courses_actions } from '../../../../store/actions/courses';
import SubMenu from '../SubMenu/SubMenu';
import Synchro from './Access/Synchro';


const Access = ({course,onCloseSubMenu}) => {
    const dispatch = useDispatch();

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        course: {}
    });
    const groupAccess =  useSelector(state => state.courses);

    useEffect(() => {
        dispatch(courses_actions.get_method_enrol_course(course.id));

    },[]);

    const clickSubMenu = (type,open_param,cohorte) => {
        setOpenSubMenu({
            type: document.querySelector('#typeresource').selectedOptions[0].text.toUpperCase(),
            open: open_param,
            cohorte: cohorte
        })
    }

    const deleteAccess = async (methodType)=>{

        const alert_delet = await Swal.fire({
            title: "¿Seguro que desea eliminar el Acceso?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })
    
        if(alert_delet.isConfirmed === true){
            await courses_actions.delete_method_enrol_course(course.id, methodType.id);
            dispatch(courses_actions.get_method_enrol_course(course.id));
        } 
    }
    return ( 
        <div>
            <div>
                { openSubMenu.open ? <div className="absolute z-10 bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl"></div> : null }
                <div className="flex m-4 justify-center">
                    <div className="w-2/5 flex items-center justify-center bg-blue-lighter border border-blue-lighter rounded-l text-blue-dark">
                        <select id="typeresource" name="typeseach" className="w-full text-black focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent sm:text-sm rounded-md">
                            <option value="synchro">Síncrono</option>
                        </select>
                    </div>
                    <button onClick={() => clickSubMenu('Recursos',!openSubMenu.open)}
                        className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4  py-1 px-2 ml-2 rounded" type="button">
                        Agregar
                    </button>
                </div>
                <div>
            </div>
                { groupAccess.loading_courses_method_enrol === false ?
                        groupAccess.courses_method_enrol.length > 0 ?
                        groupAccess.courses_method_enrol.map(method=><Grupos methodType={method} deleteMethod={deleteAccess}/>):
                            <p className=" text-center">No tienes Grupos Agregados</p>
                    :<p className="">Cargando...</p>     
 
                }
            </div>
            { openSubMenu.open ?   
                    <SubMenu>
                        <div>
                            <div className="min-w-full  submenu ">
                                <div className="border w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                    <div className=" flex justify-between items-center text-2xl p-4"> 
                                        <p className="mr-1">{ openSubMenu.type }</p> 
                                        <div className="flex" onClick={ () => setOpenSubMenu(false) }>
                                            <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '-1px', marginLeft: '0.4rem'}}/>
                                        </div>
                                    </div>
                                    {
                                        openSubMenu.type === 'SÍNCRONO' ?
                                        <Synchro  course={course} onCloseSubMenu = {setOpenSubMenu}/>:
                                        null
                                    }
                                </div>
                            </div>
                        </div>

                    </SubMenu> 
                :  null }
        </div>
    );
}

const Grupos = ({methodType,deleteMethod})=>{

    return(
        <div className="flex flex-wrap flex-row items-center m-3 mb-5 justify-between">
            <FontAwesomeIcon icon={faUsers} className=" cursor-pointer opacity-80 hover:opacity-100 mr-1 text-2xl"/>
            <p className={` text-lg`}>{methodType.name}</p>
            <div>          
                <FontAwesomeIcon icon={faTrash} 
                    className="text-red-500 cursor-pointer opacity-80 hover:opacity-100 ml-1 text-2xl" 
                    onClick = {()=>deleteMethod(methodType)}></FontAwesomeIcon>          
            </div>
        </div>
    );
}
export default Access;