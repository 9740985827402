import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { cohorts_actions } from '../../../../../store/actions/cohorts';
import { user as UserAdmin } from '../../../../../config/user_token';
const AgregarCohorte = ({ onCloseSubMenu }) => {
    const dispatch = useDispatch();
    let userData = JSON.parse(localStorage.getItem('user'));
    const categories = useSelector(state => state.categories);
    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        cohorte: {}
    });
    let categoria = new Array();

    const orderCategori = function (categoriasuperior, path) {

        if (categoriasuperior.children.length > 0) {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
            categoriasuperior.children.map(categoriachildren => {
                orderCategori(categoriachildren, `${path} / ${categoriachildren.name}`);
            });
        } else {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
        }
    }

    categories.categories.map(categoriesraiz => {
        //if(userData.fideicomisoid === null){
            if (categoriesraiz.parent == 0 && categoriesraiz.hasOwnProperty('children')) {
                orderCategori(categoriesraiz, `${categoriesraiz.name}`);
            }
        //}else{//Se agrega Validacion para las categorias que pertenecen el administrador
            //UserAdmin.fideicomiso.map(fideicomiso=>{
                //if (categoriesraiz.name.toLowerCase() == fideicomiso.toLowerCase() && categoriesraiz.hasOwnProperty('children')) {
                    //orderCategori(categoriesraiz, `${categoriesraiz.name}`);
                //}
            //});
        //}
    });

    const validate_add = Yup.object().shape({
        name: Yup.string('Escribe el nombre de tu curso')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            descriptionformat: 1,
            theme: '',
            categorytype: 'id',
            categoryvalue: 0

        },
        validationSchema: validate_add,
        onSubmit: values => {
            values.categoryvalue = document.querySelector('#categoryvalue').value;
            handleSubmit(values)
        },
    });

    const handleSubmit = async (cohort) => {
        await cohorts_actions.cohorts_create(cohort);
        onCloseSubMenu(false);
        dispatch(cohorts_actions.get_all_cohorts());
    }
    const clickSubMenu = (type, open_param, cohorte) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            cohorte: cohorte
        })
    }
    return (
        <form className="formularios" onSubmit={formik.handleSubmit}>
            <div className="campo">
                <label className="text-md" htmlFor="">Nombre del Grupo</label>
                <input onChange={formik.handleChange} type="text" name="name" placeholder="Nombre del cohorte" value={formik.values.name} />
                <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
            </div>

            <div className="campo">
                <label className="text-md" htmlFor="">Descripción del grupo</label>
                <textarea onChange={formik.handleChange} type="text" name="description" placeholder="Descripción del grupo" rows="4" value={formik.values.description} />
                <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
            </div>

            <div className="campo">
                <label className="mb-2" htmlFor="">Categorias</label>
                <select onChange={formik.handleChange} name="categoryvalue" id="categoryvalue" value={formik.values.categoryvalue}>
                    {
                        userData.ismanager == false ? 
                        <option className="bg-blue-600" value={0} selected >Superior</option>
                        :""
                    }
                    {categories.loading_categories ? 'Cargando...' :
                        categories.categories.length > 0
                            ? categoria.map(item =>
                                <option className="bg-blue-600" value={item.id} key={item.id}>{item.path}</option>
                            )
                            : null}
                </select>
                <p className="text-red-600 text-sm">{formik.errors.categoryvalue ? <div>{formik.errors.categoryvalue}</div> : null}</p>
            </div>
            <div className="block-inline text-center ">
                <button type="submit" style={{ maxWidth: "45%" }}
                    className="mx-auto text-center rounded-md bg-blue-500 bg-opacity-90 px-4 py-2 w-48 text-white border border-blue-300 hover:bg-opacity-100">Guardar</button>
            </div>

        </form>
    );
}

export default AgregarCohorte;