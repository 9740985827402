import { combineReducers } from 'redux'
import { users_reducers as users } from './users'
import { courses_reducers as courses } from './courses'
import { categories_reducers as categories } from './categories'
import { badges_reducers as badges } from './badges'
import { rankings_reducers as rankings  } from './rankings'
import { cohorts_reducers as cohorts  } from './cohorts'
import { authentication } from './authentication'
import { meetings_reducers as meetings } from './meetings'
import { rewards_reducers as rewards } from './rewards'
import { calendar_reducers as calendar } from './calendar'
import { reporter_reducers as reporter } from './reporter'
import { forums_reducers as forums } from './forums'
import { communications_reducers as communications } from './communications'
import { tutors_reducers as tutors } from './tutors'
import { attempts_scorm_reducers as attempts_scorm } from './attemptScorm'
import { attempts_quiz_reducers as attempts_quiz } from './attemptQuiz'
import { dc3_reducers as dc3 } from './dc3'


const allReducers = combineReducers({
    users,
    courses,
    categories,
    badges,
    rankings,
    cohorts,
    authentication,
    meetings,
    rewards,
    calendar,
    reporter,
    forums,
    communications,
    tutors,
    attempts_scorm,
    attempts_quiz,
    dc3
})

export default allReducers