import { combineReducers } from "redux";
import api_iac from "../../config/instance_axios";
import { user } from '../../config/user_token';

export const events_service = {
    get_all_events,
    events_create,
    events_edit,
    events_delete
};

async function get_all_events() {
    const request_events = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_get_all_events&
    moodlewsrestformat=json&
    userid=${user.userid}`);

    if (Array.isArray(request_events.data)) {
        const events = request_events.data
        return { events };
    }

    const error = request_events.data;
    return { error };
}

async function events_create(event, selected_cohort, meetings_select, courses_select) {
    let id = 0;
    let tipo = '';
    //if (Object.keys(meetings_select).length === 0) {
        id = courses_select.id;
        tipo = 'course';
    /*}
    else {
        id = meetings_select.id;
        tipo = 'meeting';
    }*/

    if (typeof(id)=='undefined') {
        id=1;
    }

    let formData = new FormData();
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'targett_create_event');
    formData.append('moodlewsrestformat', 'json');
    formData.append('event[0][usercreated]', user.userid);
    formData.append('event[0][name]', event.name);
    formData.append('event[0][startdate]', event.startdate);
    formData.append('event[0][enddate]', event.enddate);
    formData.append('event[0][description]', event.description);
    formData.append('event[0][area]', selected_cohort[0].value);
    formData.append('event[0][namearea]', selected_cohort[0].name);
    formData.append('event[0][bgcolor]', event.bgcolor);
    formData.append('event[0][tipo]', tipo);
    formData.append('event[0][idtipo]', id);
    formData.append('event[0][mode]', event.mode);
    formData.append('event[0][fideicomiso]', 3);

    const request_event_create = await api_iac.post(`webservice/rest/server.php`, formData);

    if (typeof request_event_create.data === 'object') {
        return 1;
    }

    const error = request_event_create.data;
    return { error };
}

async function events_edit(event) {
    let id = 0;
    let tipo = '';

    if (typeof event.meeting === 'string' && event.hasOwnProperty('meeting')) {
        parseInt(event.meeting);
        id = event.meeting;
        tipo = 'meeting';
    }
    else if (event.hasOwnProperty('meeting')){ 
        id = event.meeting;
        tipo = 'meeting';
    }

    if (typeof event.course === 'string' && event.hasOwnProperty('course')) {
        parseInt(event.course);
        id = event.course;
        tipo = 'course';
    }
    else if (event.hasOwnProperty('course')) {
        id = event.course;
        tipo = 'course';
    }


    let formData = new FormData();
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'targett_edit_event');
    formData.append('moodlewsrestformat', 'json');
    formData.append('event[0][id]', event.id);
    formData.append('event[0][name]', event.title);
    formData.append('event[0][startdate]', event.start);
    formData.append('event[0][enddate]', event.end);
    formData.append('event[0][description]', event.notes);
    formData.append('event[0][area]', parseInt(event.area));
    formData.append('event[0][namearea]', event.namearea);
    formData.append('event[0][bgcolor]', event.bgcolor);
    formData.append('event[0][tipo]', tipo);
    formData.append('event[0][idtipo]', id);
    formData.append('event[0][mode]', event.mode);

    const request_event_edit = await api_iac.post(`webservice/rest/server.php`, formData);

    if (typeof request_event_edit.data === 'object') {
        return 1;
    }

    const error = request_event_edit.data;
    return { error };
}

async function events_delete(event) {
    const request_event_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&
    wsfunction=targett_delete_event&
    moodlewsrestformat=json&
    id=${event.id}`);

    console.log(request_event_delete);
    if (typeof request_event_delete.data === 'object') {
        return 1;
    } else {
        return 0
    }
}