import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faSearch, faChartLine, faFileAlt, faTrophy, faGift, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import { ExportToCsv } from 'export-to-csv';
import { useDispatch ,useSelector } from 'react-redux';
import moment from 'moment';

import { dc3_actions } from '../../../store/actions/dc3';
import { reports_actions } from '../../../store/actions/reports';
import { users_actions } from '../../../store/actions/users';
import Header from './Header';
import SubMenu from './SubMenu/SubMenu';
import ListUserDc3 from '../../Aside/DetallesMenu/Reportes/ListDC3'

let user = JSON.parse(localStorage.getItem('user'));

const Reportes = ({onClose}) => {
    const dispatch = useDispatch();
    const coursesDC3 =  useSelector(state => state.dc3);
    const [courseSelected,setcourseSelected] =  useState(0);
    const [startDate,setstartDate] =  useState(0);
    const [endDate,setendDate] =  useState(0);
    const [valitestartDate, setvalitestartDate] = useState();
    const [valiteendDate, setvaliteendDate] = useState();
    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        courseid: 0
    });
    const [usersCities, setUsersCities] = useState([]);
    const [loadedCities, setLoadedCities] = useState(false);
    const [loadingAllCerts, setLoadingAllCerts] = useState(false);

    const csvData = [];



    useEffect(()=>{
        console.log('Open thing');
        users_actions.get_cities().then((a_list)=> {
            console.debug('Read list');
            //console.debug(arguments);
            console.debug(a_list);
            
            setUsersCities(a_list.ret_cities);
            setLoadedCities(true);
        });
    },[]);

    const getReporte_dateCourse = async()=>{
        let regioniac = document.querySelector("#regioniac").value;

        let result = await reports_actions.get_date_course(regioniac, startDate, endDate);
        let index = 0;
        if (result && result.length) {
            result.map((item)=>{
                //console.log(item);
                if ((typeof(item.courses)!=='undefined') && (item.courses.length > 0)) {
                    item.courses.map((course,indexc)=>{
                        csvData[index] = {
                            "fullname":item.fullname,
                            "username":item.username,
                            "course":course.namecourse,
                            "start date":course.start,
                            "Finish date":course.finish,
                            "Course completation":course.coursecompletation,
                            "Grade":course.grade
                        };
                        index++;
                    });
                }
            });
            Swal.fire('Reporte', "Reporte Generado", 'success');

            console.log(csvData);

            
            const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            filename:'Reporte_'+regioniac+'_'+ new Date() ,
            title: 'Reporte_'+regioniac+'_'+ new Date(),
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };

            const csvExporter = new ExportToCsv(options);

            csvExporter.generateCsv(csvData);
        }
    }
    
    const opneListUserDC3 = async()=>{
        dispatch(dc3_actions.get_list_user_dc3(courseSelected, startDate, endDate));
        if(courseSelected > 0){
            setOpenSubMenu({
				type: 'Lista Usuarios con DC3',
				open: true,
				courseid: courseSelected
            })
            
        }
    }
    const validateStartDate = (e)=>{

        let date  = moment(e.target.value).unix();
        if(endDate === 0){
            setvalitestartDate(true)
            setstartDate(date)

        }else if(endDate < date){
            document.querySelector("#startdate").value="";
            setvalitestartDate(false)
            setstartDate(0)
        }else{
            setvaliteendDate(true)
            setvalitestartDate(true)
            setstartDate(date)
        }

    }
    const validateendDate = (e)=>{
        let date  = moment(e.target.value).add({hours:23, minute:59, second:59}).unix();

        if(startDate === 0){
            setvaliteendDate(true)
            setendDate(date)

        }else if(startDate > date){

            document.querySelector("#enddate").value="";
            setendDate(0)
            setvaliteendDate(false)
        }else{
            setvaliteendDate(true)
            setvalitestartDate(true)
            setendDate(date)
        }

    }
    const getAllCertsZip = (e) => {
        setLoadingAllCerts(true);
        console.log('call get all certs...');

        Swal.fire({
            title: 'Descargar certificados',
            text: "Esta operación le permitira descargar todos los certificados emitidos por la plataforma. Pero puede tardar algunos minutos. ¿Desea continuar?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: "Cancelar"
        }).then((l_getallconfirm) => {

            if (l_getallconfirm.isConfirmed) {

                Swal.fire({
                    title: 'Solicitando certificados...',
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        let timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer()
                            if (content) {
                                const b = content.querySelector('b')
                                if (b) {
                                    b.textContent = Swal.getTimerLeft()
                                }
                            }
                        }, 100)
                    },
                });

                reports_actions.getAllCertsURL().then((pathpobj) => {
                    var anchor = false;
                    setLoadingAllCerts(false);
                    Swal.close();
                    //console.log(pathpobj);
                    if (pathpobj.status==200) {
                        //console.log(JSON.parse(path.obj(pathpobj.response)));
                        if (pathpobj.data) {
                            
                            if (pathpobj.data.exception) {
                                Swal.fire('Certificados', ((pathpobj.data.debuginfo)?(pathpobj.data.debuginfo):(pathpobj.data.exception)), 'error');
                            } else {
                                console.log(pathpobj.data);
                                if (typeof(pathpobj.data.packageurl)==='undefined') {
                                    Swal.fire('Certificados', "No se recibió el enlace de descarga. Intente de nuevo más adelante", 'error');
                                } else {
                                    console.log('execute download of: ' + pathpobj.data.packageurl);
                                    anchor = document.createElement('a');
                                    anchor.href = pathpobj.data.packageurl;
                                    anchor.download = pathpobj.data.packageurl.replace(/^.*[\\\/]/, '');
                                    //anchor.target = '_blank';

                                    // Append to the DOM
                                    document.body.appendChild(anchor);

                                    // Trigger `click` event
                                    anchor.click();

                                    // Remove element from DOM
                                    document.body.removeChild(anchor);
                                }
                            }
                        } else {
                            console.log('No data found');
                            console.log(pathpobj);
                        }
                    } else {
                        Swal.fire('Certificados', "Ocurrió un error (" + pathpobj.status + ") en el servidor.", 'error');
                    }
                });
            } else {
                setLoadingAllCerts(false);
            }
        });
    }
    return ( 
        <div className="bg-gray-100 w-1/4 rounded-xl h-80 scroll">
            <div>
                { openSubMenu.open ? <div className="absolute bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl z-10"></div> : null }
                <Header title="Reportes" onClose={onClose} />
                <div className="">
                    <div>
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            Selecciona Region a Obtener:
                        </label>
                        <div className="flex flex-wrap items-stretch w-full mb-4 relative justify-center">
                                {/*((user)&&(user.ismanager)&&(user.city))?
                                <select id="regioniac" className="appearance-none block w-1/2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                    <option value={user.city}>{user.city}</option>
                                </select>:
                                (!loadedCities)?
                                <div className="loadingstr">Cargando regiones...</div>:
                                (usersCities)?
                                <select id="regioniac" className="appearance-none block w-1/2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                    {usersCities.map(acity=><option value={acity}>{acity}</option>)}
                                </select>:*/
                                <select id="regioniac" className="appearance-none block w-1/2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                    <option value="Aguascalientes">Aguascalientes</option>
                                    <option value="Cuernavaca">Cuernavaca</option>
                                    <option value="Guadalajara">Guadalajara</option>
                                    <option value="Hermosillo">Hermosillo</option>
                                    <option value="Monterrey Santa Catarina">Monterrey Santa Catarina</option>
                                    <option value="Monterrey Norte">Monterrey Norte</option>
                                    <option value="Saltillo">Saltillo</option>
                                    <option value="Toluca">Toluca</option>
                                </select>}
                            { ( ((user)&&(user.ismanager)&&(user.city))||(loadedCities))?
                            <div className="flex -mr-px">
                                <span className={`flex items-center leading-normal bg-blue-500 rounded rounded-l-none border border-l-0 
                                    border-grey-light px-3 whitespace-no-wrap text-white text-sm cursor-pointer`} onClick={()=>getReporte_dateCourse()}>Reporte</span>
                            </div>:<></>
                            }
                        </div>
                    </div>
                    <hr className='my-5'
                        style={{
                            color: "rgb(169, 169, 169)",
                            backgroundColor: "rgb(169, 169, 169)",
                            height: "1px",
                            borderColor: "rgb(169, 169, 169)"
                        }}
                    />
                    <div>
                        <hr />
                        <br />
                        <div className="text-center"><button className={`rounded-xl ${loadingAllCerts?"bg-gray-500":"bg-blue-500"} text-white px-2 py-2 w-36`} disabled={loadingAllCerts} onClick={()=>getAllCertsZip()}>Obtener todos los certificados</button></div>
                    </div>
                    {/*
                        !coursesDC3.loading_dc3 ?
                        coursesDC3.dc3s.courses.length > 0 ?
                        <>
                            <div>
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Reporte DC3
                                </label>
                                <div className="flex flex-wrap items-stretch w-full mb-4 relative justify-center">
                                    <ListDC3 courses={coursesDC3.dc3s.courses} courseSelect ={setcourseSelected}/>
                                    <div className="flex -mr-px">
                                        <span className={`flex items-center leading-normal bg-blue-500 rounded rounded-l-none border border-l-0 
                                            border-grey-light px-3 whitespace-no-wrap text-white text-sm cursor-pointer`} onClick={()=>{opneListUserDC3()}}>Mostrar Usuarios</span>
                                    </div>
                                </div>
                            </div>
                            {
                                courseSelected > 0 ?
                                    <div className="flex flex-wrap -mx-3 mb-1">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                            Fecha Inico
                                            </label>
                                            < InputDate id={"startdate"} change={validateStartDate} isValate={valitestartDate}/>
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                                Fecha Fin
                                            </label>
                                            < InputDate id={"enddate"} change={validateendDate} isValate={valiteendDate}/>
                                        </div>
                                    </div>
                                    :<></>
                            }
                        </>
                        : <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        No hay cursos con DC3
                        </label>
                    : <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        Cargando
                        </label>
                    */}
                </div>
            </div>
            {
                openSubMenu.open ? 
                    <div>
                        <SubMenu>
                            <div>
                                <div className="min-w-full  submenu">
                                    <div className="w-1/3 bg-gray-100 h-80 rounded-xl">
                                        <div className=" flex justify-between items-center text-2xl p-4">
                                            <p>{openSubMenu.type}</p>
                                            <div className="flex"
                                                onClick={() => setOpenSubMenu({type: '',open: false,})}>
                                            <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{ top: '-1px', marginLeft: '-0.4rem' }} />
                                            </div>
                                        </div>
                                        <ListUserDc3 onCloseSubMenu={setOpenSubMenu} courseid={openSubMenu.courseid}/>
                                    </div>
                                </div>
                            </div>
                        </SubMenu>
                    </div>
                : null
            }
        </div>
    );
}

const ListDC3 = ({courses,courseSelect})=>{
    const onSelectCourse =(e)=>{
        console.log(e.target.value);
        courseSelect(e.target.value);
    }
    return(
    <select className="appearance-none block w-1/2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="listcourse" onChange={(e)=>{onSelectCourse(e)}}>
        <option value="0">Selecciona un Curso</option>
        {
            courses.map(course=><option value={course.id} key={course.id}>{course.fullname}</option>)
        }
    </select>)
}
const InputDate =({id, change , isValate})=>{
    let menssage = id === "startdate" ? "Fecha Inicio debe de ser menor que la fecha Fecha Final" : "Fecha Fin debe de mayor que la Fecha Inicio";
    return(
        <>
        <input 
            className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" 
            id={id} type="date" 
            onChange={(e)=>{
                change(e);
            }}/>
            { isValate === false 
                ?  <p class="text-red-500 text-xs italic">{menssage}</p> 
                : <></> 
            }
        </>
    );
}

export default Reportes;
