import moment from 'moment';
import { treeHandlers } from 'react-hyper-tree';
import Swal from 'sweetalert2'
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';


export const courses_service = {
    get_all_courses,
    courses_edit,
    courses_delete,
    courses_create,
    get_contents_course,
    delete_activity,
    add_activity_url_course,
    add_activity_resource_course,
    add_activity_scorm_course,
    get_method_enrol_course,
    delete_method_enrol_course,
    get_group_category_course,
    group_synchro,
    add_activity_exam_course,
    add_activity_dctres_course,
    add_activity_forum_course,
    add_activity_communication_course,
    get_courses
};

async function get_all_courses() {
    let request_courses;
    
    if (user.istutor) {
        request_courses = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=targett_get_courses_tutoring&moodlewsrestformat=json`);
    } else {
        request_courses = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_course_get_courses_by_field&moodlewsrestformat=json`);
    }

    if (Array.isArray(request_courses.data.courses)) {
        const courses = request_courses.data.courses.filter(item => item.id != 1);
        return { courses };
    }

    const error = request_courses.data;
    return { error };
}

async function courses_create(course, examen, urls, files, scorms) {

    let request_course_photo;
    let files_post = [];
    let scorms_post = [];

    //Upload img course
    if (course.overviewfiles !== '') {
        let formDataPhoto = new FormData();
        formDataPhoto.append('token', user.token);
        formDataPhoto.append('newfile', course.overviewfiles);
        request_course_photo = await api_iac.post(`webservice/upload.php`, formDataPhoto);
    }

    //format bigint
    course.enddate = course.enddate === '' ? 0 : moment(course.enddate).unix();
    course.startdate = course.startdate === '' ? 0 : moment(course.startdate).unix();


    course.shortname = course.fullname
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
        ;
    let formDataCourse = new FormData();
    formDataCourse.append('wstoken', user.token);
    formDataCourse.append('wsfunction', 'core_course_create_courses');
    formDataCourse.append('moodlewsrestformat', 'json');
    formDataCourse.append('courses[0][categoryid]', course.categoryid);
    formDataCourse.append('courses[0][fullname]', course.fullname);
    formDataCourse.append('courses[0][shortname]', course.shortname);
    formDataCourse.append('courses[0][summary]', course.summary);
    formDataCourse.append('courses[0][startdate]', course.startdate);
    formDataCourse.append('courses[0][enddate]', course.enddate);

    if (course.overviewfiles !== '') {
        formDataCourse.append('courses[0][overviewfiles]', request_course_photo.data[0].itemid);
    }
    const request_courses_create = await api_iac.post(`webservice/rest/server.php`, formDataCourse);
    console.log(request_courses_create);
    if (Array.isArray(request_courses_create.data)) {
        return 1;
    }

    const error = request_courses_create.data;
    return { error };
}

async function courses_edit(course) {
    console.log(course);
    let request_course_photo;
    console.log(course.overviewfiles.length === undefined);
    if (course.overviewfiles.length === undefined) {
        let formDataPhoto = new FormData();
        formDataPhoto.append('token', user.token);
        formDataPhoto.append('newfile', course.overviewfiles);
        request_course_photo = await api_iac.post(`webservice/upload.php`, formDataPhoto);
    }
    course.enddate = moment(course.enddate).unix() < 0 ? 0 : moment(course.enddate).unix();
    course.startdate = moment(course.startdate).unix() < 0 ? 0 : moment(course.startdate).unix();

    let formDataCourse = new FormData();
    formDataCourse.append('wstoken', user.token);
    formDataCourse.append('wsfunction', 'core_course_update_courses');
    formDataCourse.append('moodlewsrestformat', 'json');
    formDataCourse.append('courses[0][categoryid]', course.categoryid);
    formDataCourse.append('courses[0][id]', course.id);
    formDataCourse.append('courses[0][fullname]', course.fullname);
    formDataCourse.append('courses[0][shortname]', course.shortname);
    formDataCourse.append('courses[0][summary]', course.summary);
    formDataCourse.append('courses[0][startdate]', course.startdate);
    formDataCourse.append('courses[0][enddate]', course.enddate);
    if (course.overviewfiles.length === undefined) {
        formDataCourse.append('courses[0][overviewfiles]', request_course_photo.data[0].itemid);
    }

    const request_courses_edit = await api_iac.post('webservice/rest/server.php', formDataCourse);

    if (request_courses_edit.data.hasOwnProperty('warnings')) {
        if (request_courses_edit.data.warnings.length === 0) {
            return 1;
        }
    }

    const error = request_courses_edit.data;
    return { error };

}

async function courses_delete(course) {

    const request_courses_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_course_delete_courses&moodlewsrestformat=json&courseids[0]=${course.id}`);

    if (request_courses_delete.data.warnings.warningcode === 'unknowncourseidnumber' || request_courses_delete.data.hasOwnProperty('errorcode')) {
        return 0;
    } else {
        return 1
    }
}

async function get_contents_course(courseid) {
    const request_modules = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_course_get_contents&moodlewsrestformat=json&courseid=${courseid}`);


    if (typeof request_modules.data[0] === "object") {
        const modulos = request_modules.data[0];
        return { modulos };
    }

    const error = request_modules.data;
    return { error };
}

async function delete_activity(actividad) {
    const request = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_course_edit_module&id=${actividad.id}&action=delete&moodlewsrestformat=json`);

    if (request.data === "") {
        let success = true;
        return { success }
    }
    let error = request;
    return { error }
}

async function add_activity_url_course(actividad) {
    let formActivy = new FormData();
    formActivy.append('activity[courseid]', actividad.courseid);
    formActivy.append('activity[url][0][url]', actividad.url);
    formActivy.append('activity[url][0][urlname]', actividad.fullname);
    formActivy.append('wstoken', user.token);
    formActivy.append('moodlewsrestformat', 'json');
    formActivy.append('wsfunction', 'core_course_add_activity_url_course');

    const request = await api_iac.post(`webservice/rest/server.php`, formActivy);
    if (request.data.warnings.length === 0) {
        const result = true;
        return { result };
    }

    const error = request.data;
    return { error };

}

async function add_activity_resource_course(actividad) {

    let formData = new FormData();
    formData.append('token', user.token);
    formData.append('newfile', actividad.file);
    const request_course_files = await api_iac.post(`webservice/upload.php`, formData);

    let formActivy = new FormData();
    formActivy.append('resource[courseid]', actividad.courseid);
    formActivy.append('resource[resource][0][itemid]', request_course_files.data[0].itemid);
    formActivy.append('resource[resource][0][name]', actividad.fullname);
    formActivy.append('wstoken', user.token);
    formActivy.append('moodlewsrestformat', 'json');
    formActivy.append('wsfunction', 'core_course_add_activity_resource_course');

    const request = await api_iac.post(`webservice/rest/server.php`, formActivy);

    if (request.data.warnings.length === 0) {
        const result = true;
        return { result };
    }

    const error = request.data;
    return { error };
}

async function add_activity_scorm_course(scorm) {

    let formData = new FormData();
    formData.append('token', user.token);
    formData.append('newfile', scorm.file);
    const request_course_files = await api_iac.post(`webservice/upload.php`, formData);

    let formActivy = new FormData();
    formActivy.append('wstoken', user.token);
    formActivy.append('wsfunction', 'targett_create_scorm');
    formActivy.append('moodlewsrestformat', 'json');
    formActivy.append('draftitemid', request_course_files.data[0].itemid);
    formActivy.append('courseid', scorm.courseid);
    formActivy.append('title', scorm.fullname);
    formActivy.append('maxattempt', scorm.attempts);
    formActivy.append('grademethod', scorm.grademethod);
    formActivy.append('completion', scorm.completionusegrade); // Se debe de recibir una calificacion para completar la actividad
    formActivy.append('completionview', scorm.completionview);
    formActivy.append('completionscorerequired', scorm.completionscorerequired);
    formActivy.append('completionscoredisabled', scorm.completionscoredisabled);
    formActivy.append('completionpassed', scorm.completionapproved);
    formActivy.append('completionfinished', scorm.completionfinish);
    formActivy.append('whatgrade', 0);
    formActivy.append('availabilityconditionsjson', scorm.availability);


    const request = await api_iac.post(`webservice/rest/server.php`, formActivy);

    if (request.data.errors.length === 0) {
        const result = true;
        return { result };
    }

    const error = request.data;
    return { error };

}

async function get_method_enrol_course(courseid) {
    const request = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_enrol_get_course_enrolment_methods&moodlewsrestformat=json&courseid=${courseid}`);
    if (Array.isArray(request.data)) {
        const gruposAccess = request.data.filter(item => item.type === "cohort");
        return { gruposAccess };
    }

    const error = request.data;
    return { error };
}

async function delete_method_enrol_course(courseid, enrolid) {
    const request = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_role_delete_synchron_cohort&moodlewsrestformat=json&synchron[id]=${courseid}&synchron[action]=delete&synchron[instance]=${enrolid}&synchron[confirm]=1`);
}

async function get_group_category_course(contextid) {
    const request = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_cohort_search_cohorts&moodlewsrestformat=json&context[contextid]=${contextid}&query=`);
    if (Array.isArray(request.data.cohorts)) {
        const group = request.data.cohorts;
        return { group };
    }

    const error = request.data;
    return { error };
}

async function group_synchro(synchro) {
    const request = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_role_create_synchron_cohort&moodlewsrestformat=json&synchron[name]=${synchro.fullname}&synchron[status]=${synchro.statusActivo}&synchron[customint1]=${synchro.group}&synchron[courseid]=${synchro.courseid}`);
    if (request.data.status === 'success') {
        const result = true;
        return { result };
    }

    const error = request.data;
    return { error };
}

async function add_activity_exam_course(exam) {

    let formActivy = new FormData();
    formActivy.append('wsfunction', 'core_course_add_activity_quiz_course');
    formActivy.append('wstoken', user.token);
    formActivy.append('moodlewsrestformat', 'json');
    formActivy.append('quiz[name]', exam.fullname);
    formActivy.append('quiz[description]', exam.description);
    formActivy.append('quiz[courseid]', exam.courseid);
    formActivy.append('quiz[attempts]', 3);
    formActivy.append('quiz[shuffleanswers]', 1);
    formActivy.append('quiz[grademethod]', 1);
    formActivy.append('quiz[csv]', exam.csv);

    const request = await api_iac.post(`webservice/rest/server.php`, formActivy);
    if (request.data === null) {
        const result = true;
        return { result };
    }

    const error = request.data;
    return { error };

    /* 
    formData.append('newfile', scorm.file);
    const request_course_files = await api_iac.post(`webservice/upload.php`, formData);

    formActivy.append('courseid', scorm.courseid);
    formActivy.append('draftitemid', request_course_files.data[0].itemid);
    formActivy.append('title', scorm.fullname);

    */

}

async function add_activity_dctres_course(dctres) {

    let formActivy = new FormData();
    formActivy.append('wsfunction', 'core_course_add_activity_dctres_course');
    formActivy.append('wstoken', user.token);
    formActivy.append('moodlewsrestformat', 'json');
    formActivy.append('dctres[courseid]', dctres.courseid);
    formActivy.append('dctres[dctres][0][itemid]', dctres.activity);
    formActivy.append('dctres[dctres][0][name]', dctres.name);
    formActivy.append('dctres[dctres][0][intro]', dctres.intro);
    formActivy.append('dctres[dctres][0][companyname]', dctres.companyname);
    formActivy.append('dctres[dctres][0][companycurp]', dctres.companycurp);
    formActivy.append('dctres[dctres][0][coursestartdate]', dctres.startdate);
    formActivy.append('dctres[dctres][0][courseenddate]', dctres.enddate);
    formActivy.append('dctres[dctres][0][coursetematic]', dctres.area);
    formActivy.append('dctres[dctres][0][printhours]', dctres.duration);
    formActivy.append('dctres[dctres][0][agentstps]', dctres.agentstps);
    formActivy.append('dctres[dctres][0][availability]', dctres.availability);

    const request = await api_iac.post(`webservice/rest/server.php`, formActivy);


    if (request.data.warnings.length === 0) {
        const result = true;
        return { result };
    }

    const error = request.data;
    return { error };

}

async function add_activity_forum_course(forum) {

    let formActivy = new FormData();
    formActivy.append('wsfunction', 'targett_generate_forum');
    formActivy.append('wstoken', user.token);
    formActivy.append('moodlewsrestformat', 'json');
    formActivy.append('courseid', forum.courseid);
    formActivy.append('name', forum.name);
    formActivy.append('intro', forum.intro);

    const request = await api_iac.post(`webservice/rest/server.php`, formActivy);

    if (typeof request.data === 'object') {
        const result = true;
        return { result };
    }

    const error = request.data;
    return { error };

}

async function add_activity_communication_course(channel) {

    let formActivy = new FormData();
    formActivy.append('wsfunction', 'targett_generate_channel');
    formActivy.append('wstoken', user.token);
    formActivy.append('moodlewsrestformat', 'json');
    formActivy.append('courseid', channel.courseid);
    formActivy.append('title', channel.name);

    const request = await api_iac.post(`webservice/rest/server.php`, formActivy);


    if (typeof request.data === 'object') {
        const result = true;
        return { result };
    }

    const error = request.data;
    return { error };

}

async function get_courses(categoryid) {
    let request_courses;

    let formData = new FormData();
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'core_course_get_courses_by_field');
    formData.append('moodlewsrestformat', 'json');

    formData.append('field', 'category');
    formData.append('value', categoryid);

        request_courses = await api_iac.post(`webservice/rest/server.php`,formData);
    
    if (Array.isArray(request_courses.data.courses)) {
        const courses = request_courses.data.courses.filter(item => item.id != 1);
        return { courses };
    }

    const error = request_courses.data;
    return { error };
}