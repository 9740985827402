import React, { useEffect,useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';


import evaluacion from '../../../../../assets/img/cursos/evaluacion.png'
import { cohorts_actions } from '../../../../../store/actions/cohorts';
import SubMenuAddUserCohort from '../../SubMenu/SubMenuAddUserCohort';
import Select,{components } from 'react-dropdown-select';
import api_iac from '../../../../../config/instance_axios';
import { users_actions } from '../../../../../store/actions/users';

import { user as UserAdmin } from '../../../../../config/user_token';
//&wsfunction=tool_dataprivacy_get_users&moodlewsrestformat=json&query=0
const AddusersCohort = ({cohorte,onCloseSubMenu}) => {
    const dispatch = useDispatch();
    let userData = JSON.parse(localStorage.getItem('user'));

    const [user_cohorts_select, set_user_cohorts_select] = useState([]);
    const [user_cohorts_propect, set_user_cohorts_prospect] = useState([]);
    const [user_cohorts_add, set_user_cohorts_add] = useState([]);
    const [user_cohorts_remove, set_user_cohorts_remove] = useState([]);
    const [users_search, set_users_search] = useState([]);
    useEffect(() => {
        cohorts_members();
    },[]);

    //Obtiene los datos de los usuario dentro del grupo
    async function cohorts_members(){
        let members_users = await cohorts_actions.cohorts_members_users(cohorte.id);
        await set_user_cohorts_select(members_users);
    }
    //Busca los usuarios
    async function searhcUser(user){
        await buscaruser(user);
    }
    async function buscaruser(user){
        let users = await users_actions.users_seach(user);
        let members_users = await cohorts_actions.cohorts_members_users(cohorte.id);
        let tempProspect = [];
        users.users.filter(user=>{
            let isUserAdded = members_users.find(usercohort=>{
                if(user.id === usercohort.id){
                    return true;
                }
                return false;
            })

            if(!!isUserAdded){
                ////temp.push(user);
            }else{
                //if(UserAdmin.fideicomisoid){
                    //UserAdmin.fideicomisoid.map(fideicomisoid=>{
                        //if(fideicomisoid == user.fideicomisoid)
                            //tempProspect.push(user);
                    //});
                //}else{
                    tempProspect.push(user);
                //}
            }
        });
        set_user_cohorts_prospect(tempProspect);
    }
    //Agregar usuarios al grupo
    const add_users_cohort = async values =>{
        let values_option = [];
        
        await Array.from(values.target.selectedOptions).map(option=>{
            values_option.push(option.value);
        });
        set_user_cohorts_add(values_option);
        console.log(document.querySelector("#addselectusers").selectedOptions==null);

    };

    async function add_users_cohort_save(){
        await cohorts_actions.add_members_cohort(cohorte,user_cohorts_add);
        await cohorts_members();
        await searhcUser(document.querySelector("#searchuser").value);
        let select = document.querySelector('#addselectusers');
        Array.from(select.selectedOptions).map(item=>{item.selected=false})
    }
    
    //Remover usuarios de grupo
    const remove_users_cohort = async values =>{
        let values_option = [];
        await Array.from(values.target.selectedOptions).map(option=>{
            values_option.push(option.value);
        });
        set_user_cohorts_remove(values_option);
    };

    const remover_users_cohort_save = async ()=>{
        await cohorts_actions.remove_members_cohort(cohorte,user_cohorts_remove);
        await cohorts_members();
        await searhcUser(document.querySelector("#searchuser").value);
    }

    return ( 
        <div>
            <div className="formularios"> 
                <div className="campo">
                    <h3 className="text-center">Usuarios Potenciales</h3>
                    <label className="mb-2" htmlFor="">Buscar Usuarios</label>
                    <input type="text" placeholder="Buscar Usuarios" onChange={(value)=>{searhcUser(value.target.value)}} id="searchuser"/>
                    <select name="addselect[]" multiple="multiple" size="20" 
                        className="form-control no-overflow w-full" style={{color:"black",background:"white"}}
                        onChange={(values)=>{add_users_cohort(values)}}
                        id="addselectusers">
                        <optgroup label="">
                            {
                                user_cohorts_propect.map(user=>(<option value={user.id} key={user.id}>{user.fullname} ({user.extrafields[0].value})</option>))
                            }
                        </optgroup>
                    </select>
                    <button type="submit" style={{maxWidth:"45%"}}
                        className="mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  border border-blue-300 hover:bg-opacity-100"
                        onClick={()=>{add_users_cohort_save()}}>Agregar</button>
                </div>
                <div className="campo text-center">
                    <label className="mb-2 text-lg" htmlFor="">Usuarios Agregados</label>
                    <SelectGroup users={user_cohorts_select} user_selecteds={(values)=>remove_users_cohort(values)}></SelectGroup>
                    <button type="submit" style={{maxWidth:"45%"}}
                        className="mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  border border-blue-300 hover:bg-opacity-100"
                        onClick={()=>{remover_users_cohort_save()}}>Quitar</button>
                </div>
            </div>
        </div>
    );
}
const SelectGroup = ({users,user_selecteds})=>{
    return(
        <select name="addselect[]" multiple="multiple" size="20" 
            className="form-control no-overflow w-full" style={{color:"black",background:"white"}}
            onChange={(values)=>{user_selecteds(values)}}>
            <optgroup label="">
                {
                    users.map(user=>(<option value={user.id}>{user.firstname} {user.lastname} ({user.email})</option>))
                }
            </optgroup>
        </select>
    );
}
export default AddusersCohort;
