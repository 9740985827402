import React from 'react'
import moment from 'moment';

/* Recibe los parámetros del objeto event definido en el CalendarScreen.js */
export const CalendarEvent = ({ event }) => {
    const { title, mode, linktipo, start, } = event;

    return (
        <div>
            <span>{mode} - </span>
            <span>{title} - </span>
            {/* <span>  {newstart}</span> */}
            <a href={linktipo} target="_blank" rel='noreferrer' className="hover:bg-blue-900 p-1">Link</a>
        </div>
    )
}
