import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { users_actions } from '../../../store/actions/users';
import { rankings_actions } from '../../../store/actions/rankings';
import Swal from 'sweetalert2';

import exportar from '../../../assets/img/insignias/exportar.png';
import buscar_usuario from '../../../assets/img/user/buscar_usuario.svg';
import Header from './Header';
import { rankings_service } from '../../../store/services/rankings';
import { user as UserAdmin } from '../../../config/user_token';


const Ranking = ({ onClose }) => {

    //const rankings = useSelector(state => state.rankings);
    //const dispatch = useDispatch();

    const [isAddingPoints, setAddingPoints] = useState(true);
    const [busquedaRes, guardarBusquedadRes] = useState([]);
    const [busquedaShow, guardarBusquedadShow] = useState(false);
    const [loadingRankings, setLoadingRankings] = useState(false);

    const [rankings, setRankings] = useState([]);

    const get_more_ranking_rows = (offset) => {
        rankings_service.get_ranking_page(10, offset).then(res => {

            if (Array.isArray(res.request_rankings)) {
                setRankings(rankings.concat(res.request_rankings));
            } else if ((typeof (res.request_rankings.data) != 'undefined') && (Array.isArray(res.request_rankings.data))) {
                if( res.request_rankings.data > 0){
                    if (typeof res.request_rankings.data[0].employment === 'undefined') {
                        res.request_rankings.data.map(usr => { usr.employment = '-'; });
                    }
                }
                setRankings(rankings.concat(res.request_rankings.data));
            } else {
                console.log('**** Error leyendo rankings: ' + JSON.stringify(res.request_rankings));
                setRankings([]);
            }

            setLoadingRankings(false);

            /*res.request_rankings.then((result) => {
                if (Array.isArray(result)) {
                    setRankings(rankings.concat(result));
                } else {

                    console.log('*** Error leyendo rankings: ' + result)
                }
            });*/
        });
    }

    useEffect(() => {
        setLoadingRankings(true);
        get_more_ranking_rows(0);
    }, []);

    //if (rankings.length==0) {
    //setLoadingRankings(true);
    //get_more_ranking_rows(0);
    //}


    const fuzzyMatch = (pattern, str) => {
        pattern = '.*' + pattern.split('').join('.*') + '.*';
        const re = new RegExp(pattern);
        return re.test(str);
    }

    const handleSearchInput = async e => {
        let rankingelements = document.querySelectorAll('.ranking_list tr');
        if (e.target.value.length >= 4) {
            //console.log(e.target.value);
            let typeseach = document.querySelector("#typeseach").selectedOptions[0].value;
            let searchuser = e.target.value;
            await searchUser(searchuser,typeseach);

            //rankingelements.forEach((currelement) => {
                //if (fuzzyMatch(e.target.value.toLowerCase(), currelement.querySelector('p').innerHTML.toLowerCase())) {
                    //currelement.classList.remove('hidden');
                //} else {
                    //currelement.classList.add('hidden');
                //}
            //})
        //} else {
            //rankingelements.forEach((currelement) => {
                //currelement.classList.remove('hidden');
            //})
        }
    }

    const searchUser = async (search, typeseach) => {
        if (search !== '') {
            let result = await users_actions.get_user_field(search, typeseach);
            
            //Valida el admin tiene varios fideicomisos
            //if(Array.isArray(UserAdmin.fideicomisoid)){
                //let resultado = result.filter(user=> {
                    ////let isuser = UserAdmin.fideicomisoid.find(id=>user.fideicomisonum == id);
                    //let isuser = !UserAdmin.ismanager
                    //if(isuser){
                        //return user;
                    //}
                    //return null;
                    //user.fideicomisonum == UserAdmin.fideicomisoid
                //})
                //guardarBusquedadRes(resultado);
            //}else{
                guardarBusquedadRes(result);
           // }
            guardarBusquedadShow(true)
        } else {
            guardarBusquedadRes([])
            guardarBusquedadShow(false)
        }
    }

    const handleSaveClick = async e => {
        let userSelectorElement = document.querySelector("#rankselecteduser");
        let usertmpobject = { id: userSelectorElement.value };
        //console.log("Save click...");
        //console.log(state);
        //console.log(this);
        console.log(usertmpobject);
        if (userSelectorElement) {
            if (isAddingPoints) {
                //console.log('Agregar puntos...');
                rankings_actions.add_ranking_points(usertmpobject, document.querySelector('#pointsnum').value).then((result) => { console.log('Despues de agregar puntos...'); /*dispatch(rankings_actions.get_all_rankings());*/ setRankings([]); get_more_ranking_rows(0); });

            } else {
                //console.log('Quitar puntos...');
                rankings_actions.remove_ranking_points(usertmpobject, document.querySelector('#pointsnum').value).then((result) => { console.log('Despues de quitar puntos...'); /*dispatch(rankings_actions.get_all_rankings());*/ setRankings([]); get_more_ranking_rows(0); });
            }
            //console.log(document.querySelector('#pointsnum').value);
            //console.log(userSelectorElement.value);

        } else {
            Swal.fire(
                'Ranking',
                'Por favor, seleccione un usario para hacer la operación',
                'info',
            );
        }

    }

    const handleRankScroll = e => {
        let lelement = e.target;
        //console.log(e);
        if ((lelement.scrollHeight - lelement.scrollTop) > lelement.clientHeight) {
            console.log('still scrolling');
        } else {
            console.log('reached bottom');
            get_more_ranking_rows(rankings.length);
        }
        //console.log((lelement.scrollHeight - lelement.scrollTop));
        //console.log(lelement.clientHeight);
    }

    return (
        <div className="bg-gray-100 w-1/4 rounded-xl h-80 scroll" onScroll={handleRankScroll}>
            <Header title="Ranking" onClose={onClose} />

            <div className="mb-4">
                <div className=" my-4">
                    <p className="mb-1">Opciones:</p>
                    <div className="flex">
                        <a className={`text-sm border-white cursor-pointer rounded-full text-white py-2 px-1 mr-1 ${isAddingPoints ? 'border-b-4 bg-iacblack' : 'border-b-2 opacity-20'} w-1/2 text-center mb-1`} href="#" onClick={() => setAddingPoints(true)} style={{border: '1px solid #797974'}}>{ isAddingPoints ? <p className="flex items-center justify-center"><span className="dot mr-2"></span> Agregar puntos</p> : <p className="flex items-center justify-center text-black"><span className="dot-black mr-2"></span> Agregar puntos</p>} </a>
                        <a className={`text-sm border-white cursor-pointer rounded-full text-white py-2 mr-1 ml-1 ${isAddingPoints ? 'border-b-2 opacity-20' : 'border-b-4 bg-iacblack'} w-1/2 text-center mb-1`} href="#" onClick={() => setAddingPoints(false)} style={{border: '1px solid #797974'}}> { !isAddingPoints ? <p className="flex items-center justify-center"><span className="dot mr-2"></span> Quitar puntos</p> : <p className="flex items-center justify-center text-black"><span className="dot-black mr-2"></span> Quitar puntos</p>}</a>
                    </div>
                </div>

                <div>
                    <p className="  text-sm mb-2">{isAddingPoints ? 'Asignar' : 'Quitar'} puntos a:</p>
                    <p className="hidden  text-sm">Filtrar usuario:</p>

                    {
                        <div>
                            <div className="mt-1 relative rounded-md shadow-sm mb-2">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-600 sm:text-sm">
                                        <FontAwesomeIcon icon={faSearch} className="cursor-pointer opacity-80 hover:opacity-100 " />
                                    </span>
                                </div>
                                <input onChange={handleSearchInput} type="text" name="search" id="search" className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 pr-12 sm:text-sm border-gray-300 rounded-md" style={{border: '1px solid #797974'}}/>
                                <div className="hidden absolute inset-y-0 right-0 flex items-center">
                                    <label for="typeseach" className="sr-only">typeseach</label>
                                    <select id="typeseach" name="typeseach" className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-600 sm:text-sm rounded-md">
                                        <option value="username">Numero empleado</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    }

                    {busquedaShow ?
                        <div>
                            {busquedaRes.length > 0 ?
                                <div className="mt-5">
                                    <p className="text-sm mb-1">Resultados: {busquedaRes.length}</p>
                                    <select className="w-full bg-indigo-100" id="rankselecteduser" size="5">
                                        {busquedaRes.map(user => (
                                            <option value={user.id} className="text-black text-sm">{user.firstname} {user.lastname} </option>
                                        ))
                                        }
                                    </select>
                                </div>
                                : <p className="">No tienes usuarios agregados</p>}
                        </div>
                        : null}

                    <div className="hidden bg-white h-12 flex p-1">
                        <input className="bg-blue-400 w-11/12 p-1 mr-2" type="text" />
                        <img className="" src={buscar_usuario} alt="buscar_usuario" />
                    </div>
                    <p className="hidden  text-sm text-right">Filtros</p>
                </div>

                <div className="">
                    <p className=" text-sm mb-2">Cuántos puntos deseas {isAddingPoints ? 'asignar' : 'quitar'}:</p>

                    <div className="flex justify-between">
                        <input id="pointsnum" defaultValue="5" type="number" className="w-16 px-2 py-2 rounded-md" style={{border: '1px solid #797974'}}/>
                        <button className="bg-blue-500 text-white px-12 py-2 rounded-md opacity-90 hover:opacity-100" onClick={handleSaveClick}>Guardar</button>
                    </div>
                </div>
            </div>

            <div>
                <p className=" text-sm mb-1">Resultados:</p>

                <table className="w-full text-center border-separate mb-8 bg-indigo-200">
                    <thead>
                        <tr>
                            <td className="bg-opacity-40 bg-white">Posición</td>
                            <td className="bg-opacity-40 bg-white">Puntos</td>
                            <td className="bg-opacity-40 bg-white">Nombre</td>
                            <td className="bg-opacity-40 bg-white">Puesto</td>
                        </tr>
                    </thead>
                    <tbody className="ranking_list bg-white">
                        {loadingRankings ? '...'
                            : rankings.length > 0 ? rankings.map(item => <ItemTable user={item} />) : ''}
                    </tbody>
                </table>
            </div>

            <div className="flex flex-row flex-wrap justify-end">
                <div className=" m-2 flex items-center cursor-pointer hidden"> <p className="mr-1">Exportar</p> <img src={exportar} alt="agregar" /> </div>
            </div>
        </div>

    );
}

const ItemTable = ({ user }) => {
    return (
        <tr className="text-sm h-10 border border-white">
            <td>{user.rankposition}</td>
            <td>{user.rankpoints}</td>
            <td>
                <div className="flex justify-start text-center items-center">
                    <div className="hidden mr-4"> <img className="rounded-full w-10 h-10" src={user.profileimage} alt="foto" /> </div>
                    <p>{user.firstname} {user.lastname}</p>
                </div>
            </td>
            <td>{user.employment}</td>
        </tr>
    );
}

export default Ranking;