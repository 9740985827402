import { dc3_constants } from "../constants/dc3";

const initialState = {
    dc3s:[],
    loading_dc3:null,
    error_dc3:null,

    userlistdc3:[],
    loading_userlistdc3:true,
    error_userlistdc3:null,

}

export function dc3_reducers(state = initialState, action){
    switch(action.type){

        //Get Course DC3
        case dc3_constants.DC3_REQUEST:
            return{
                ...state,
                loading_dc3: action.payload
            };
        case dc3_constants.DC3_SUCCESS:
            return{
                ...state,
                loading_dc3: false,
                error_dc3: null,
                dc3s:action.payload
            };
        case dc3_constants.DC3_FAILURE:
            return{
                ...state,
                loading_dc3: false,
                error_dc3: action.payload
            };
        
        //Get User DC3
        case dc3_constants.USER_LIST_DC3_REQUEST:
            return {
                ...state,
                loading_userlistdc3: action.payload
            };
        case dc3_constants.USER_LIST_DC3_SUCCESS:
            console.log(action.payload);
            return {
                ...state,
                loading_userlistdc3: false,
                error_userlistdc3: false,
                userlistdc3: action.payload
            };
        case dc3_constants.USER_LIST_DC3_FAILURE:
            return{
                ...state,
                loading_userlistdc3: true,
                error_userlistdc3: action.payload,

            }
        default:
            return state
    }

}