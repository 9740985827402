import { reporter_constants } from '../constants/reporter';


const initialState = {
    reporter: [],
    loading_reporter: null,
    error_reporter: null,

    reporter_filter: [],
    loading_reporter_filter: null,
    error_reporter_filter: null,
};

export function reporter_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET REPORTER--------------//
        case reporter_constants.REPORTER_REQUEST:
            return {
                ...state,
                loading_reporter: action.payload,
            };
        case reporter_constants.REPORTER_SUCCESS:
            return {
                ...state,
                loading_reporter: false,
                reporter: action.payload,
                error_reporter: null
            };
        case reporter_constants.REPORTER_FAILURE:
            return {
                ...state,
                loading_reporter: false,
                error_reporter: action.payload
            };
            //------------GET REPORTER_FILTER--------------//
        case reporter_constants.REPORTER_FILTER_REQUEST:
            return {
                ...state,
                loading_reporter_filter: action.payload,
            };
        case reporter_constants.REPORTER_FILTER_SUCCESS:
            return {
                ...state,
                loading_reporter_filter: false,
                reporter_filter: action.payload,
                error_reporter_filter: null
            };
        case reporter_constants.REPORTER_FILTER_FAILURE:
            return {
                ...state,
                loading_reporter_filter: false,
                error_reporter_filter: action.payload
            };
        default:
            return state
    }
}