import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Select from 'react-dropdown-select';
import moment from 'moment';
import { CompactPicker } from 'react-color'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { UnmountClosed } from 'react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { events_actions } from '../../store/actions/calendar';
import Header from '../../Components/Aside/DetallesMenu/Header';


const EditarEvento = ({ openMenu, closeMenuEdit }) => {
    const dispatch = useDispatch();
    const cohorts = useSelector(state => state.cohorts);
    const meetings = useSelector(state => state.meetings);
    const courses = useSelector(state => state.courses);

    const event = openMenu.event;

    const options_cohorts = cohorts.cohorts.map((option) => ({
        ...option,
        label: option.name,
        value: option.id,
        cohortid: option.id
    }));

    const opciones = []
    options_cohorts.map(cohort => {
        if (cohort.id !== 1 || cohort.name !== "webservice" || cohort.idnumber !== "webservice") {
            opciones.push(cohort);
        }
    });

    const options_meetings = meetings.meetings.map((optionMeeting) => ({
        ...optionMeeting,
        label: optionMeeting.topic,
        value: optionMeeting.id,
        meetingid: optionMeeting.id
    }));

    const opcionesMeeting = []
    options_meetings.map(meeting => {
        opcionesMeeting.push(meeting);

    });

    const options_courses = courses.courses.map((optionCourse) => ({
        ...optionCourse,
        label: optionCourse.fullname,
        value: optionCourse.id,
        meetingid: optionCourse.id
    }));

    const opcionesCourse = []
    options_courses.map(course => {
        opcionesCourse.push(course);

    });

    const [area_select, set_area_select] = useState(0);
    const [namearea_select, set_namearea_select] = useState("");

    const [meetings_select, set_meetings_select] = useState(0);
    const [courses_select, set_courses_select] = useState(0);


    const [selectedColor, setSelectedColor] = useState(event.bgcolor);
    let [startDate, setstartDate] = useState("");
    let [endDate, setendDate] = useState("");
    const [isOpenCourse, setIsOpenCourse] = useState(event.tipo === 'course' ? true : false);
    const [isOpenMeeting, setIsOpenMeeting] = useState(event.tipo === 'meeting' ? true : false);

    let formatTimeStart = 0;
    let formatTimeEnd = 0;

    formatTimeStart = moment(event.start).format("YYYY-MM-DD[T]HH:mm");
    formatTimeEnd = moment(event.end).format("YYYY-MM-DD[T]HH:mm");

    useEffect(() => {

        formik.setFieldValue('start', formatTimeStart)
        setstartDate(formatTimeStart);
        formik.setFieldValue('end', formatTimeEnd)
        setendDate(formatTimeEnd);

        formik.setFieldValue('course', event.tipo === 'course' ? event.idtipo : null)
        set_courses_select(event.tipo === 'course' ? event.idtipo : null);
        formik.setFieldValue('meeting', event.tipo === 'meeting' ? event.idtipo : null)
        set_meetings_select(event.tipo === 'meeting' ? event.idtipo : null);

        formik.setFieldValue('area', event.area)
        set_area_select(event.area);
        formik.setFieldValue('namearea', event.namearea)
        set_namearea_select(event.namearea);
    }, [])

    const validate_add = Yup.object().shape({
        mode: Yup.string()
            .required('El campo es requerido'),
        title: Yup.string('Escribe el nombre de tu evento')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        start: Yup.string('')
            .required('El campo es requerido'),
        end: Yup.string('')
            .required('El campo es requerido'),
        notes: Yup.string('Escribe la descripcion de tu evento')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(30, 'Debe ser menor a 30 caracteres'),

        /* area: Yup.string('')
            .required('El campo es requerido') */
    });

    const formik = useFormik({
        initialValues: { ...event },
        validationSchema: validate_add,
        onSubmit: values => {

            handleSubmit(values)
        },
    });

    const handleSubmit = async (event) => {

        if (isOpenCourse === true) {
            setIsOpenMeeting(false);
            set_meetings_select("");
            delete event.meeting;
            delete event.tipo;
            delete event.idtipo;
        }
        else {
            setIsOpenMeeting(true);
            set_courses_select("");
            delete event.course;
            delete event.tipo;
            delete event.idtipo;
        }

        event.bgcolor = selectedColor;

        await events_actions.events_edit(event);
        closeMenuEdit(false);
        dispatch(events_actions.get_all_events());
    }

    const onClickDelete = async () => {
        const alert_delet = await Swal.fire({
            title: "¿Seguro que deseas eliminar el evento?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed === true) {
            await events_actions.events_delete(event);
            dispatch(events_actions.get_all_events());
            closeMenuEdit(false);
        }
    }

    return (
        <form className="formularios" onSubmit={formik.handleSubmit}>
            <label className="" htmlFor="">Selecciona el tipo evento</label>
            <div className="flex justify-center text-center mb-5  mt-1">
                <div>
                    <div className="radio-button flex items-center border-black border px-4 py-2 w-64 rounded-md">
                        <input type="radio" name="tipo" id="tipo1" onClick={() => { setIsOpenCourse(true); setIsOpenMeeting(false) }} value="Cursos" />
                        <label htmlFor="tipo1">Cursos</label>
                    </div>
                    <UnmountClosed isOpened={isOpenCourse}>
                        <div className="campo text-black">
                            <select onChange={formik.handleChange} name="course" value={formik.values.course}>
                                <option value={0} selected disabled>Selecciona un curso</option>
                                {
                                    opcionesCourse.map(item =>
                                        <option value={item.value} select={item.id === event.idtipo && event.tipo === 'course' ? "true" : 'false'}>{item.label}</option>
                                    )
                                }
                            </select>

                            {/* <Select
                                options={opcionesCourse}
                                name="course"
                                onChange={(value) => onChangeCourse(value)}
                            /> */}
                        </div>
                    </UnmountClosed>
                </div>
                {/* <div>
                    <div className="radio-button flex items-center border-black border px-4 py-2 w-64 rounded-md">
                        <input type="radio" name="tipo" id="tipo2" onClick={() => { setIsOpenMeeting(true); setIsOpenCourse(false) }} value="Conferencias" />
                        <label htmlFor="tipo2">Conferencias</label>
                    </div>
                    <UnmountClosed isOpened={isOpenMeeting}>
                        <div className="campo text-black">
                            <select className="rounded-md" onChange={formik.handleChange} name="meeting" value={formik.values.meeting}>
                                <option value={0} selected disabled>Selecciona una conferencia</option>
                                {
                                    opcionesMeeting.map(item =>
                                        <option value={item.value} select={item.id === event.idtipo && event.tipo === 'meeting' ? "true" : 'false'}>{item.label}</option>
                                    )
                                }
                            </select>
                        </div>
                    </UnmountClosed>
                </div> */}
            </div>
            <div className="campo">
                <label className="" htmlFor="">Modalidad del evento</label>
                <div className="flex justify-around">
                    <div class="mr-4">
                        <input onChange={formik.handleChange} id="modalidad1" type="radio" name="mode" className="hidden" checked={formik.values.mode === "Presencial" ? true : false} value="Presencial" />
                        <label for="modalidad1" className="flex items-center cursor-pointer">
                            <span class="w-4 h-4 inline-block mr-1 border border-black rounded"></span>
                            Presencial</label>
                    </div>
                    <div class="mr-4">
                        <input onChange={formik.handleChange} id="modalidad2" type="radio" name="mode" className="hidden" checked={formik.values.mode === "Virtual" ? true : false} value="Virtual" />
                        <label for="modalidad2" className="flex items-center cursor-pointer">
                            <span class="w-4 h-4 inline-block mr-1 border border-black rounded"></span>
                            Virtual</label>
                    </div>
                </div>
                <p className="text-red-600 text-sm">{formik.errors.mode ? <div>{formik.errors.mode}</div> : null}</p>
            </div>
            <div className="campo text-black">
                <label className="" htmlFor="">Nombre del evento</label>
                <input className="rounded-md" onChange={formik.handleChange} type="text" name="title" placeholder="" value={formik.values.title} />
                <p className="text-red-600 text-sm">{formik.errors.title ? <div>{formik.errors.title}</div> : null}</p>
            </div>
            <div className="campo text-black">
                <div className="inline-flex items-baseline justify-between">
                    <div>
                        <label className="text-md" htmlFor="startdate">Fecha de inicio</label>
                        <br/>
                        <input className="rounded-md" onChange={
                            (e) => {
                                setstartDate(e.target.value);
                                formik.setFieldValue('start', e.target.value)
                            }
                        }
                            type="datetime-local" name="start" value={startDate} />
                        <p className="text-red-600 text-sm">{
                            formik.errors.start ?
                                <div>{formik.errors.start}</div> :
                                null}
                        </p>

                        {/* <input className="" onChange={formik.handleChange} type="datetime-local" id="start" name="start" placeholder="" value={formatStart} />
                        <p className="text-red-600 text-sm">{formik.errors.start ? <div>{formik.errors.start}</div> : null}</p> */}
                    </div>
                    <div className="m-3"></div>
                    <div>
                        <label className="text-md" htmlFor="enddate">Fecha de finalizacion</label>
                        <br/>
                        <input className="rounded-md" onChange={
                            (e) => {
                                setendDate(e.target.value);
                                formik.setFieldValue('end', e.target.value)
                            }
                        } type="datetime-local" name="end" value={endDate} />
                        <p className="text-red-600 text-sm">{
                            formik.errors.end ?
                                <div>{formik.errors.end}</div> :
                                null}
                        </p>

                        {/* <input className="" onChange={formik.handleChange} type="datetime-local" id="end" name="end" placeholder="" value={formatEnd} />
                        <p className="text-red-600 text-sm">{formik.errors.end ? <div>{formik.errors.end}</div> : null}</p> */}
                    </div>
                </div>
            </div>
            <div className="campo text-black">
                <label className="" htmlFor="">Descripción del evento</label>
                <textarea className="rounded-md" onChange={formik.handleChange} type="text" name="notes" placeholder="" value={formik.values.notes} rows="2" cols="50" />
                <p className="text-red-600 text-sm">{formik.errors.notes ? <div>{formik.errors.notes}</div> : null}</p>
            </div>
            <div className="campo text-black">
                <label className="mb-2" htmlFor="">Seleccionar área</label>
                <select className="rounded-md" onChange={
                    (e) => {
                        let index = e.target.selectedIndex;
                        let label = e.target[index].text;

                        formik.setFieldValue('area', e.target.value)
                        set_area_select(e.target.value);
                        formik.setFieldValue('namearea', label)
                        set_namearea_select(label);
                    }
                }
                    name="area" value={area_select}>
                    <option value={0} selected disabled>Selecciona un grupo</option>
                    {
                        opciones.map(item =>
                            <option value={item.value} select={item.id === event.area ? "true" : 'false'}>{item.label}</option>
                        )
                    }
                </select>

                {/* <Select
                    options={opciones}
                    name="area"
                    onChange={(value) => onChange(value)}
                /> */}
                <p className="text-red-600 text-sm">{formik.errors.area ? <div>{formik.errors.area}</div> : null}</p>
            </div>
            <div className="flex items-end mb-5 ">
                <Tippy interactive={true} placement={'bottom'} content={
                    <CompactPicker
                        color={selectedColor}
                        onChangeComplete={color => setSelectedColor(color.hex)}
                    />
                }>
                    <span class="text-center bg-iacblack px-4 py-2 w-12 h-12 rounded-md" style={{ backgroundColor: selectedColor }}></span>
                </Tippy>
                <label className="ml-5 mb-3" htmlFor="">Selecciona el color del evento</label>
            </div>

            <div className="flex justify-center">
                <button type="button" onClick={() => { onClickDelete() }} className="block mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  border border-blue-300 hover:bg-opacity-100">Eliminar</button>
                <button type="submit" className="block mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  border border-blue-300 hover:bg-opacity-100">Editar</button>
            </div>

        </form>

    );
}

export default EditarEvento;