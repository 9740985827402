
import { meetings_constants } from '../constants/meetings';
import { meetings_service } from '../services/meetings';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const meetings_actions = {
    get_all_meetings,
    meetings_create,
    meetings_edit,
    meetings_delete,
    meetings_members_users,
    add_members_meeting,
    remove_members_meeting,
    get_meetings
};

function get_all_meetings() {
    return dispatch => {
        dispatch(request());

        meetings_service.get_all_meetings()
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.meetings));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: meetings_constants.MEETINGS_REQUEST, payload: true } }
    function success(param) { return { type: meetings_constants.MEETINGS_SUCCESS, payload: param } }
    function failure(error) { return { type: meetings_constants.MEETINGS_FAILURE, payload: error } }
}

async function meetings_create(meeting) {

        await meetings_service.meetings_create(meeting)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        failure(res.error.message.toString());
                        return Swal.fire(
                            'Conferencia',
                            `${res.error.message}`,
                            'error',
                        )
                    } else {
                        success(res);
                        return Swal.fire(
                            'Conferencia',
                            'Fue agregada con exito',
                            'success'
                        )
                    }
                },
                error => {
                    failure(error.toString());
                    alert_actions.error(error.toString());
                    return Swal.fire(
                        'Error',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => failure(e.toString()))

    function request() { return { type: meetings_constants.MEETINGS_CREATE_REQUEST, payload: true } }
    function success(param) { return { type: meetings_constants.MEETINGS_CREATE_SUCCESS, payload: param } }
    function failure(error) { return { type: meetings_constants.MEETINGS_CREATE_FAILURE, payload: error } }
}

async function meetings_edit(meeting) {

    await meetings_service.meetings_edit(meeting)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString());
                    return Swal.fire(
                        'Conferencia',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Conferencia',
                        'Actualizada con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: meetings_constants.MEETINGS_EDIT_REQUEST, payload: true } }
    function success(param) { return { type: meetings_constants.MEETINGS_EDIT_REQUEST, payload: param } }
    function failure(error) { return { type: meetings_constants.MEETINGS_EDIT_REQUEST, payload: error } }
}

async function meetings_delete(meeting) {

    await meetings_service.meetings_delete(meeting)
        .then(
            res => {
                if (res == 0) {
                    failure(res);
                    return Swal.fire(
                        'Conferencia',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Conferencia',
                        'Fue borrado con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: meetings_constants.MEETINGS_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: meetings_constants.MEETINGS_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: meetings_constants.MEETINGS_DELETE_FAILURE, payload: error } }
}

async function meetings_members_users(meetingid) {

    return await meetings_service.meetings_members_users(meetingid)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString())
                    //dispatch(failure(res.error.message.toString()));
                } else {

                    return res.members_users

                    //dispatch(success(res.categories));
                }
            },
            error => {
                failure(error.toString())
                alert_actions.error(error.toString())
                //dispatch(failure(error.toString()));
                //dispatch(alert_actions.error(error.toString()));
            }
        ).catch(e => {
            failure(e.toString())

            //dispatch(failure(e.toString()))
        })
    /* return dispatch => {
        dispatch( request() );
        
    }; */

    function request() { return { type: meetings_constants.MEETINGS_MEMBERS_REQUEST, payload: true } }
    function success(param) { return { type: meetings_constants.MEETINGS_MEMBERS_SUCCESS, payload: param } }
    function failure(error) { return { type: meetings_constants.MEETINGS_MEMBERS_FAILURE, payload: error } }
}

async function add_members_meeting(meeting, users) {
    await meetings_service.add_members_meeting(meeting, users)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString())
                    //dispatch();
                    return Swal.fire(
                        'Conferencia',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    success(res)
                    //dispatch(success(res));
                    return Swal.fire(
                        'Conferencia',
                        'El/los usuarios fueron agregados con exitos',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString())
                //dispatch(failure(error.toString()));
                alert_actions.error(error.toString());
                //dispatch(alert_actions.error(error.toString()));
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            failure(e.toString());
            //dispatch(failure(e.toString()));
        })
    /* return dispatch=>{
        dispatch( request() );
    } */
    function request() { return { type: meetings_constants.ADD_MEMBERS_MEETINGS_REQUEST, payload: true } }
    function success(param) { return { type: meetings_constants.ADD_MEMBERS_MEETINGS_SUCCESS, payload: param } }
    function failure(error) { return { type: meetings_constants.ADD_MEMBERS_MEETINGS_FAILURE, payload: error } }
}

async function remove_members_meeting(meeting, users) {
    await meetings_service.remove_members_meeting(meeting, users)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    //dispatch(failure(res.error.message.toString()));
                    return Swal.fire(
                        'Conferencia',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    //dispatch(success(res));
                    return Swal.fire(
                        'Conferencia',
                        'Los usuarios seleccionados fueron excluidos con exito.',
                        'success'
                    )
                }
            },
            error => {
                //dispatch(failure(error.toString()));
                //dispatch(alert_actions.error(error.toString()));
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return e.toString();
            //dispatch(failure(e.toString()))
        })
    return dispatch => {
        dispatch(request());

    }
    function request() { return { type: meetings_constants.REMOVE_MEMBERS_MEETINGS_REQUEST, payload: true } }
    function success(param) { return { type: meetings_constants.REMOVE_MEMBERS_MEETINGS_SUCCESS, payload: param } }
    function failure(error) { return { type: meetings_constants.REMOVE_MEMBERS_MEETINGS_FAILURE, payload: error } }
}

function get_meetings() {

    return dispatch => {
        dispatch(request());

        meetings_service.get_meetings()
            .then(
                meetings => {
                    if (meetings.length > 0) {
                        dispatch(success(meetings));
                    } else {
                        dispatch(failure(meetings.error.toString()));
                        //dispatch(alertActions.error(meetings.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: meetings_constants.MEETINGS_REQUEST, payload: true } }
    function success(meetingsAll) { return { type: meetings_constants.MEETINGS_SUCCESS, payload: meetingsAll } }
    function failure(error) { return { type: meetings_constants.MEETINGS_FAILURE, payload: error } }
}