import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

import './Aside.scss';

import Estructura from './DetallesMenu/Estructura';
import Cursos from './DetallesMenu/Cursos'
import Usuarios from './DetallesMenu/Usuarios'
import Tutores from './DetallesMenu/Tutores';
import Insignias from './DetallesMenu/Insignias';
import Recompensas from './DetallesMenu/Recompensas';
import Reporteador from './DetallesMenu/Reporteador';
import Reportes from './DetallesMenu/Reportes';
import Ranking from './DetallesMenu/Ranking';
import Conferencias from './DetallesMenu/Conferencias';

import { useDispatch, useSelector } from 'react-redux';

import { users_actions } from '../../store/actions/users';
import { categories_actions } from '../../store/actions/categories';
import { cohorts_actions } from '../../store/actions/cohorts';
import { courses_actions } from '../../store/actions/courses';
import { badges_actions } from '../../store/actions/badges';
import { rankings_actions } from '../../store/actions/rankings';
import { rewards_actions } from '../../store/actions/rewards';
import { meetings_actions } from '../../store/actions/meetings';
import { reporter_actions } from '../../store/actions/reporter';

import { user } from '../../config/user_token';
import { dc3_actions } from '../../store/actions/dc3';


const Aside = () => {
    const history = useHistory();

    let [inicio, set_home] = useState(require('../../assets/img/aside/inicio.png').default)
    let [estructura, set_estructura] = useState(require('../../assets/img/aside/estructura.png').default)
    let [usuarios, set_usuarios] = useState(require('../../assets/img/aside/usuarios.png').default)
    let [cursos, set_cursos] = useState(require('../../assets/img/aside/cursos.png').default)
    let [tutores, set_tutores] = useState(require('../../assets/img/aside/tutores.png').default)
    let [insignias, set_insignias] = useState(require('../../assets/img/aside/insignias.png').default)
    let [recompensas, set_recompensas] = useState(require('../../assets/img/aside/recompensas.png').default)
    let [ranking, set_ranking] = useState(require('../../assets/img/aside/ranking.png').default)
    //let [reportes, set_reportes] = useState(require('../../assets/img/aside/reportes.png').default)
    let [reporteador, set_reporteador] = useState(require('../../assets/img/aside/reportes.png').default)
    /*let [analytics, set_analytics] = useState(require('../../assets/img/aside/analytics.png').default) */
    let [conferencias, set_conferencias] = useState(require('../../assets/img/aside/videoconferencias.png').default)
    let [reporteiac, set_reporteiac] = useState(require('../../assets/img/aside/reportes.png').default)

    let categories = useSelector(state => state.categories);

    const dispatch = useDispatch();
    const [componente, set_componente] = useState('')
    const [componente_active, set_componente_active] = useState(<></>)

    const onClose = () => {
        set_componente('')
        // document.querySelector('body').style.overflow = 'auto' 
    }

    /* useEffect(() => {
        const btn = document.querySelector(".mobile-menu-button");
        btn.addEventListener('click', sideBar);
    }, []);

    let sideBar = () => {
        const sidebar = document.querySelector(".aside");
        sidebar.classList.toggle("-translate-x-full");
    } */

    const handleMouseEnter = (type) => {
        switch (type) {
            case 'home': set_home(require('../../assets/img/aside/inicio_hover.png').default); break;
            case 'estructura': set_estructura(require('../../assets/img/aside/estructura_hover.png').default); break;
            case 'usuarios': set_usuarios(require('../../assets/img/aside/usuarios_hover.png').default); break;
            case 'cursos': set_cursos(require('../../assets/img/aside/cursos_hover.png').default); break;
            case 'tutores': set_tutores(require('../../assets/img/aside/tutores_hover.png').default); break;
            case 'insignias': set_insignias(require('../../assets/img/aside/insignias_hover.png').default); break;
            case 'recompensas': set_recompensas(require('../../assets/img/aside/recompensas_hover.png').default); break;
            case 'ranking': set_ranking(require('../../assets/img/aside/ranking_hover.png').default); break;
            //case 'reportes': set_reportes(require('../../assets/img/aside/reportes_hover.png').default); break;
            case 'reporteador': set_reporteador(require('../../assets/img/aside/reportes_hover.png').default); break;
            /*case 'analytics': set_analytics(require('../../assets/img/aside/analytics_hover.png').default); break; */
            case 'conferencias': set_conferencias(require('../../assets/img/aside/videoconferencias-hover.png').default); break;
            case 'reporteiac': set_reporteiac(require('../../assets/img/aside/reporteiac_hover.png').default); break;
            default:
                break;
        }
    }

    const handleMouseLeave = (type) => {
        switch (type) {
            case 'home': set_home(require('../../assets/img/aside/inicio.png').default); break;
            case 'estructura': set_estructura(require('../../assets/img/aside/estructura.png').default); break;
            case 'usuarios': set_usuarios(require('../../assets/img/aside/usuarios.png').default); break;
            case 'cursos': set_cursos(require('../../assets/img/aside/cursos.png').default); break;
            case 'tutores': set_tutores(require('../../assets/img/aside/tutores.png').default); break;
            case 'insignias': set_insignias(require('../../assets/img/aside/insignias.png').default); break;
            case 'recompensas': set_recompensas(require('../../assets/img/aside/recompensas.png').default); break;
            case 'ranking': set_ranking(require('../../assets/img/aside/ranking.png').default); break;
            //case 'reportes': set_reportes(require('../../assets/img/aside/reportes.png').default); break;
            case 'reporteador': set_reporteador(require('../../assets/img/aside/reportes.png').default); break;
            /*case 'analytics': set_analytics(require('../../assets/img/aside/analytics.png').default); break; */
            case 'conferencias': set_conferencias(require('../../assets/img/aside/videoconferencias.png').default); break;
            case 'reporteiac': set_reporteiac(require('../../assets/img/aside/reporteiac.png').default); break;
            default:
                break;
        }
    }

    const handleClick = (type) => {
        set_componente(type)
        // document.querySelector('body').style.overflow = 'hidden'  
        switch (type) {
            case 'home':
                history.push(`/`);
                window.location.reload();
                break;
            case 'estructura':
                set_componente_active(<Estructura onClose={onClose} />)
                dispatch(cohorts_actions.get_all_cohorts());
                dispatch(categories_actions.get_all_categories());
                break;
            case 'usuarios':
                set_componente_active(<Usuarios onClose={onClose} />)
                dispatch(cohorts_actions.get_all_cohorts());
                break;
            case 'cursos':
                set_componente_active(<Cursos onClose={onClose} />)
                dispatch(categories_actions.get_all_categories());
                dispatch(courses_actions.get_all_courses());
                break;
            /* case 'tutores':
                set_componente_active(<Tutores onClose={onClose} />)
                break; */
            case 'insignias':
                set_componente_active(<Insignias onClose={onClose} />)
                dispatch(badges_actions.get_all_badges());
                dispatch(courses_actions.get_all_courses());
                break;
            /* case 'recompensas':
                set_componente_active(<Recompensas onClose={onClose} />)
                dispatch(rewards_actions.get_all_rewards());
                break; */
            /* case 'ranking':
                set_componente_active(<Ranking onClose={onClose} />)
                //dispatch(rankings_actions.get_all_rankings());
                break; */
            /* case 'reportes':
                set_componente_active(<Reportes onClose={onClose} />)
                break; */
            /* case 'reporteador':
                set_componente_active(<Reporteador onClose={onClose} />)
                dispatch(reporter_actions.get_all_reporter());
                dispatch(categories_actions.get_all_categories());
                dispatch(courses_actions.get_all_courses());
                break; */
            /*case 'analytics':
                set_componente_active(<Estructura onClose={onClose} />)
                break; */
            /* case 'conferencias':
                console.log(user.istutor)
                user.istutor ? 
                    dispatch(meetings_actions.get_meetings())
                : 
                    dispatch(meetings_actions.get_all_meetings())
                ;
                set_componente_active(<Conferencias onClose={onClose} />)
                break; */
            case 'reportepocari':
                //dispatch(dc3_actions.get_course_dc3());
                //set_componente_active(<Reportes onClose={onClose} />)

                //Abrir ventana nueva.

                window.open('https://pocariaprende.com/reporteador/', '_blank');
                break;
            default:
                break;
        }
    }

    return (
        /*<aside className="aside flex bg-white w-1/4 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">*/
        <aside className="aside flex bg-white p-4 relative w-1/4 overflow-y-scroll">
            <ul style={{ height: "100vh" }}>
                <li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('home')} onMouseEnter={() => handleMouseEnter('home')}> <p onClick={() => handleClick('home')} className="cursor-pointer text-blue-500"><img src={inicio} alt="imagen" /> </p> </li>
                {   user.istutor ? <></>
                    :<li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('estructura')} onMouseEnter={() => handleMouseEnter('estructura')}> <p onClick={() => handleClick('estructura')} className="cursor-pointer text-blue-500"><img src={estructura} alt="imagen" /> </p> </li>}
                {   user.istutor ? <></>
                    :<li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('usuarios')} onMouseEnter={() => handleMouseEnter('usuarios')}> <p onClick={() => handleClick('usuarios')} className="cursor-pointer text-blue-500"><img src={usuarios} alt="imagen" /> </p> </li>}            
                <li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('cursos')} onMouseEnter={() => handleMouseEnter('cursos')}> <p onClick={() => handleClick('cursos')} className="cursor-pointer text-blue-500"><img src={cursos} alt="imagen" /> </p> </li>
                {/* {user.istutor ? <></>:<li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('tutores')} onMouseEnter={() => handleMouseEnter('tutores')}> <p onClick={() => handleClick('tutores')} className="cursor-pointer text-blue-500"><img src={tutores} alt="imagen" /> </p> </li>} */}
                {/*user.istutor ? <></>:<li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('insignias')} onMouseEnter={() => handleMouseEnter('insignias')}> <p onClick={() => handleClick('insignias')} className="cursor-pointer text-blue-500"><img src={insignias} alt="imagen" /> </p> </li>*/}
                {/* {user.istutor ? <></>:<li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('recompensas')} onMouseEnter={() => handleMouseEnter('recompensas')}> <p onClick={() => handleClick('recompensas')} className="cursor-pointer text-blue-500"><img src={recompensas} alt="imagen" /> </p> </li>} */}
                {/* {user.istutor ? <></>:<li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('ranking')} onMouseEnter={() => handleMouseEnter('ranking')}> <p onClick={() => handleClick('ranking')} className="cursor-pointer text-blue-500"><img src={ranking} alt="imagen" /> </p> </li>} */}
                {/* <li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('reportes')} onMouseEnter={() => handleMouseEnter('reportes')}> <p onClick={() => handleClick('reportes')} className="cursor-pointer text-blue-500"><img src={reportes} alt="imagen" /> </p> </li> */}
                {/* {user.istutor ? <></>:<li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('reporteador')} onMouseEnter={() => handleMouseEnter('reporteador')}> <p onClick={() => handleClick('reporteador')} className="cursor-pointer text-blue-500"><img src={reporteador} alt="imagen" /> </p> </li>} */}
                {/* <li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={ () => handleMouseLeave('analytics') } onMouseEnter={ () => handleMouseEnter('analytics') }> <p onClick={ () => handleClick('analytics') } className="cursor-pointer text-blue-500"><img src={analytics} alt="imagen"/> </p> </li> */}
                {/* <li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('conferencias')} onMouseEnter={() => handleMouseEnter('conferencias')}> <p onClick={() => handleClick('conferencias')} className="cursor-pointer text-blue-500"><img src={conferencias} alt="imagen" /> </p> </li> */}
                {<li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('reportepocari')} onMouseEnter={() => handleMouseEnter('reportepocari')}> <p onClick={() => handleClick('reportepocari')} className="cursor-pointer text-blue-500"><img src={reporteiac} alt="imagen" /> </p> </li>}
                {/* <li className="mb-4 rounded-xl hover:bg-blue-500" onMouseLeave={() => handleMouseLeave('exportcerts')} onMouseEnter={() => handleMouseEnter('exportcerts')}> <p onClick={() => handleClick('exportcerts')} className="cursor-pointer text-blue-500"><img src={exportcerts} alt="imagen" /></p></li> */}
            </ul>

            {componente !== 'home' && componente !== '' ?
                <div>
                    <div onClick={onClose} className="fixed h-screen z-10 min-w-full top-0 left-0 bg-black bg-opacity-50 overflow-hidden"></div>
                    <div className="detatlles fixed top-0 left-0 rounded-xl m-4 min-w-full">
                        {componente_active}
                    </div>
                </div>
                : <></>
            }
        </aside>
    );
}

export default Aside;

const DetallesMenu = ({ title }) => {
    return (
        <div className="bg-blue text-black">
            <h3>{title}</h3>
        </div>
    );
}
