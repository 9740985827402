import Swal from 'sweetalert2';
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';

import { cohorts_service } from './cohorts'


export const categories_service = {
    get_all_categories,
    categories_edit,
    categories_delete,
    categories_create,
    get_categorie
};

async function get_all_categories() {
    let formData = new FormData();
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'core_course_get_categories');
    formData.append('moodlewsrestformat', 'json');

    if(user.fideicomiso.length !== 1 && user.fideicomiso[0] !== ''){
        formData.append('criteria[0][key]', 'names');
        formData.append('criteria[0][value]', user.fideicomiso);
    }
    const request_categories = await api_iac.post(`webservice/rest/server.php`, formData);
    
    //const request_categories = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_course_get_categories&moodlewsrestformat=json`);
    console.log(request_categories.data);
    
    if (Array.isArray(request_categories.data)) {
        const categories = request_categories.data
        return { categories };
    }

    const error = request_categories.data;
    return { error };

}

async function get_categorie(name){
    let formData = new FormData();
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'core_course_get_categories');
    formData.append('moodlewsrestformat', 'json');

    formData.append('criteria[0][key]', 'name');
    formData.append('criteria[0][value]', name);

    const request_categories = await api_iac.post(`webservice/rest/server.php`, formData);

    if (Array.isArray(request_categories.data)) {
        const categories = request_categories.data
        return { categories };
    }

    const error = request_categories.data;
    return { error };
}

async function categories_edit(category, selected_cohort) {
    category.idnumber = string_to_slug(category.name);
    const request_categories_edit = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_course_update_categories&moodlewsrestformat=json&categories[0][id]=${category.id}&categories[0][name]=${category.name}&categories[0][idnumber]=${category.idnumber}&categories[0][parent]=${category.parent}&categories[0][description]=${category.description}&categories[0][descriptionformat]=${category.descriptionformat}&categories[0][theme]=${category.theme}`);

    if (request_categories_edit.data == null) {
        let all_cohorts;

        await cohorts_service.get_all_cohorts()
            .then(result => {
                all_cohorts = result.categories
            });

        let cohortes_category = all_cohorts.filter(cohort => cohort.id_category == category.id);

        cohortes_category.map(cohorte => {
            let is_within = selected_cohort.find(cohort => {
                console.log(cohorte);
                if (cohort.id == cohorte.id) {
                    return true;
                }
                return false;
            });
            console.log(!is_within);
            if (!is_within) {
                cohorte.id_category = 0;
                cohorts_service.cohorts_edit(cohorte, {}, 1)
            } else {
                cohorts_service.cohorts_edit(cohorte, {}, 1)
            }
        });

        selected_cohort.map(cohorte => {
            cohorte.id_category = category.id;
            cohorts_service.cohorts_edit(cohorte, {}, 1)
                .then(res => {
                    return 1;
                })
                .catch(e => {
                    return { e };
                })

        });

        return 1;
    }

    const error = request_categories_edit.data;
    return { error };

}

async function categories_delete(category) {
    const request_category_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_course_delete_categories&moodlewsrestformat=json&categories[0][id]=${category.id}&categories[0][recursive]=1`);
    if (request_category_delete.data == null) {
        return 1;
    } else {
        return 0
    }
}


async function categories_create(category, selected_cohort) {

    const request_categories_create = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_course_create_categories&moodlewsrestformat=json&categories[0][name]=${category.name}&categories[0][parent]=${category.parent}&categories[0][description]=${category.description}&categories[0][descriptionformat]=1`);

    if ( typeof request_categories_create.data === "object") {
        const result = await selected_cohort.map(async(cohorte) => {
            console.log(request_categories_create.data[0].id);
            cohorte.id_category = request_categories_create.data[0].id
            await cohorts_service.cohorts_edit(cohorte, {}, 1)
                .then(res => {
                    return 1;
                })
                .catch(e => {
                    return { e };
                })
            //.catch(e => dispatch(failure(e.toString())))
        });
        return 1;
    }else{
        const error = request_categories_create.data;
        return { error };
    }

}

function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}