import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faArchive, faEye, faTrash, faEdit, faLink, faFilePdf, faFileVideo, faClipboardList, faCertificate, faComments } from '@fortawesome/free-solid-svg-icons'

import { courses_actions } from '../../../../store/actions/courses';

import SubMenu from '../SubMenu/SubMenu';

import Activity from './Resource/Activity';


import { useHistory } from "react-router-dom";

import { user } from '../../../../config/user_token';

import courseLock from '../../../../config/lockCourse';

const Resources = ({ course, onCloseSubMenu }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const local = useRef(null);
    const modulos = useSelector(state => state.courses.courses_modules)
    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        course: {}
    });

    useEffect(() => {
        dispatch(courses_actions.get_contents_course(course.id));
    }, []);

    const clickSubMenu = (type, open_param) => {
        console.log(document.querySelector('#typeresource').selectedOptions[0].value);
        setOpenSubMenu({
            type: document.querySelector('#typeresource').selectedOptions[0].value.toUpperCase(),
            open: open_param,
        })
    }

    const deleteActivity = async (actividad) => {

        const alert_delet = await Swal.fire({
            title: "¿Seguro que desea eliminar la actividad?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed === true) {
            await courses_actions.delete_activity(actividad);
            dispatch(courses_actions.get_contents_course(course.id));
        }
    }

    const redirecToForum = (actividad, status) => {

        let idForum = actividad.instance;
        history.push(`/forum/${idForum}`);
        window.location.reload();

        /* console.log(actividad.instance);
        setOpenModalForum({
            forum: actividad.instance,
            open: status
        }) */
    }

    const redirecToCommunication = (actividad, status) => {

        let idCommunication = actividad.instance;
        history.push(`/communication/${idCommunication}`);
        window.location.reload();
    }
    /* Attempts */
    const redirecToRemoveAttemptScorm = async (actividad, status) => {

        let idScorm = actividad.instance;

        history.push(`/attemptscorm/${idScorm}`);
         //dispatch(attempts_scorm_actions.get_all_attempts_scorm(idScorm));


        //window.click(); 
        window.location.reload();
    }

    const redirecToRemoveAttemptQuiz = (actividad, status) => {

        let idQuiz = actividad.instance;
        history.push(`/attemptquiz/${idQuiz}`);
        window.location.reload();
    }

    let notErase = courseLock.find(id => id === course.id);

    return (
        <div>
            <div>
                {openSubMenu.open ? <div className="absolute z-10 bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl"></div> : null}
                <div className="flex m-4 justify-center">
                    {user.istutor ? <></> : <div className="w-5/6 flex items-center justify-center bg-blue-lighter 
                    border border-blue-300 rounded-l text-blue-dark">
                        <select id="typeresource" name="typeseach" className="text-black 
                            focus:ring-indigo-500 focus:border-indigo-500 w-full h-full py-0 pl-2 pr-7 
                            border-transparent bg-transparent sm:text-sm rounded-md">
                            {/*<option value="scorm">Scorm</option>*/}
                            {/*<option value="Url">URL</option>*/}
                            <option value="video">Video</option>
                            {/*<option value="pdf">PDF</option>*/}
                            <option value="examen">Examen</option>
                            {/*<option value="dctres">Certificado dctres</option>*/}
                            {/*<option value="foro">Foro</option>*/}
                            {/*<option value="Canal de comunicaciones">Canal de comunicaciones</option>*/}
                        </select>
                        <button onClick={() => clickSubMenu('Recursos', !openSubMenu.open)}
                            className="flex-shrink-0  text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-28  
                            border border-blue-300 hover:bg-opacity-100" type="button">
                            Agregar
                        </button>
                    </div>}
                </div>
                <div>
                </div>
                
                {
                    modulos !== null ?
                        modulos.modules.map(item => (
                            <Activiades canErase = {notErase}  recurso={item} showForum={redirecToForum} showCommunication={redirecToCommunication} showRemoveAttemptScorm={redirecToRemoveAttemptScorm} showRemoveAttemptQuiz={redirecToRemoveAttemptQuiz} deleteAc={deleteActivity} />
                        ))
                        : <p>Cargado...</p>
                }
            </div>
            {openSubMenu.open ?
                <SubMenu>
                    <div>
                        <div className="min-w-full  submenu ">
                            <div className="border w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                <div className="text-white flex justify-between items-center text-2xl p-4">
                                    <p className="mr-1 text-black">{openSubMenu.type}</p>
                                    <div className="flex items-center justify-center " onClick={() => setOpenSubMenu(false)}>
                                        <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{ top: '0px', marginLeft: '2.2rem' }} />

                                    </div>
                                </div>
                                {
                                    <Activity course={course} onCloseSubMenu={setOpenSubMenu} type={document.querySelector('#typeresource').selectedOptions[0].value.toUpperCase()} />
                                }
                            </div>
                        </div>
                    </div>

                </SubMenu>
                : null}
        </div>
    );
}

const Activiades = ({ canErase,recurso, deleteAc, showForum, showCommunication, showRemoveAttemptScorm, showRemoveAttemptQuiz }) => {
    let iconActiviad, clickEyeFunction;
    let showEyeIcon = false;
    let showTrashIcon = (!user.istutor);

    if (recurso.modname === 'scorm') {
        iconActiviad = faArchive;
        clickEyeFunction = showRemoveAttemptScorm;
        showEyeIcon = (!user.istutor);
    }

    if (recurso.modname === 'url') {
        iconActiviad = faLink;
    }

    if (recurso.modname === 'resource' && recurso.contents[0].mimetype === "video/mp4") {
        iconActiviad = faFileVideo;
    }

    if (recurso.modname === 'resource' && recurso.contents[0].mimetype === "application/pdf") {
        iconActiviad = faFilePdf;
    }

    if (recurso.modname === 'quiz') {
        iconActiviad = faClipboardList;
        clickEyeFunction = showRemoveAttemptQuiz;
        showEyeIcon = (!user.istutor);
    }

    if (recurso.modname === 'dctres' || recurso.modname === 'customcert') {
        iconActiviad = faCertificate;
    }

    if (recurso.modname === 'forum') {
        showEyeIcon = true;
        if (recurso.forumtype === 'general') {
            iconActiviad = faComments;
            clickEyeFunction = showForum;
        }

        if (recurso.forumtype === 'news') {
            iconActiviad = faComments;
            clickEyeFunction = showCommunication;
        }
    }
    return (
        <div className="flex flex-wrap flex-row items-center m-3 mb-5 justify-between">
            <div className='flex'>
                <FontAwesomeIcon icon={iconActiviad} className="text-white cursor-pointer opacity-80 hover:opacity-100 text-2xl mr-3" style={{ color: "black", width: '40px' }} />
                <p className={`text-lg text-black`}>{recurso.name}</p>
            </div>

            <div className='flex'>

                { showEyeIcon ? <div>
                    <FontAwesomeIcon icon={faEye}
                        className="text-blue-500 cursor-pointer opacity-80 hover:opacity-100 mr-2 text-2xl"
                        onClick={() => clickEyeFunction(recurso, true)}></FontAwesomeIcon>
                </div> : <></> }

                {showTrashIcon && canErase === undefined ? <div>
                    <FontAwesomeIcon icon={faTrash}
                        className="text-red-500 cursor-pointer opacity-80 hover:opacity-100 text-2xl"
                        onClick={() => deleteAc(recurso)}></FontAwesomeIcon>
                </div> : <></>}
            </div>

        </div>
    );
}
export default Resources;