
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';

export const attempts_scorm_service = {
    get_all_attempts_scorm,
    delete_attempt_scorm
};

//let user = JSON.parse(localStorage.getItem('user'));


async function get_all_attempts_scorm (idScorm) {
    const request_attempts_scorm = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_get_scorm_attempts_list&
    &moodlewsrestformat=json&
    scormid=${idScorm}`);

    if (Array.isArray(request_attempts_scorm.data)) {
        const attempts_scorm = request_attempts_scorm.data;
        return { attempts_scorm };
    }

    const error = request_attempts_scorm.data;
    return { error };
}


async function delete_attempt_scorm (row, idScorm) {
    const request_attempt_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&
    wsfunction=targett_delete_scorm_attempts&
    moodlewsrestformat=json&
    scormattempts[0][userid]=${row.userid}&
    scormattempts[0][scormid]=${idScorm}&
    scormattempts[0][attempt]=${row.numberatt}`);

    if (typeof request_attempt_delete.data === 'object') {
        return 1;
    } else {
        return 0
    }
}