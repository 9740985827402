let token = localStorage.getItem('token');
let userid;
let categoriasFideicomisos;
let fideicomisosid;
if(localStorage.getItem('user') !== null ){
    userid = JSON.parse(localStorage.getItem('user'));
    console.log(userid);
    
    if (typeof(userid.fideicomiso)==='udefined') {
		userid.fideicomiso = '';
	}
	
	if (typeof(userid.fideicomisoid)==='undefined') {
		userid.fideicomisoid = null;
	}
    
    categoriasFideicomisos =  userid.fideicomiso.split(",");
    
    if (userid.fideicomisoid !== null) {
        fideicomisosid =  userid.fideicomisoid.split(",");
    }else{
        fideicomisosid = 0;
    }
}else{
    userid = {
        userid:3,
        istutor:false,
        firstname: '',
        lastname: ''
    }
}
//console.log(userid);

export const user = {
    token,
    userid: userid.userid,
    istutor: userid.istutor,
    firstname: userid.firstname,
    lastname: userid.lastname,
    fideicomisoid: fideicomisosid,
    fideicomiso: categoriasFideicomisos,
    theme: userid.theme,
    //token : '01d00c50b7b8bf5cfb2a9df2f26a1598'
}

export const nameFideicomisos = ["Seleccione un fideicomiso","FigloSNTE 2", "FigloSNTE 8", "FigloSNTE 13", "FigloSNTE 16", "FigloSNTE 23", "FigloSNTE 25", "FigloSNTE 26", "FigloSNTE 32", "FigloSNTE 33", "FigloSNTE 47", "FigloSNTE 53", "FigloSNTE 55", "FigloSNTE 56",  "FigloSTASE", "FigloSNTSA Sinaloa", "JUBICOM 16", "JUBICOM 27"] 
