import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tree, { useTreeState } from 'react-hyper-tree'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import SubMenu from '../SubMenu/SubMenu';
import EditarCategoria from './Categoria/EditarCategoria';
import AgregarCategoria from './Categoria/AgregarCategoria';
import { categories_actions } from '../../../../store/actions/categories';
/* import three_dots from '../../../../assets/img/three_dots.svg' */



const Categorias = ({ onClose }) => {

    const categories = useSelector(state => state.categories);
    const dispatch = useDispatch();
    let [categories_tree, setstate_categories_tree] = useState([]);

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        category: {}
    });

    //Abre un submenu para agregar o editar
    const clickSubMenu = (type, open_param, category) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            category: category
        })
    }

    //Elimina la categoria
    const handleClickDelete = async category => {
        const alert_delet = await Swal.fire({
            title: "¿Seguro que desea eliminar la categoria?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed == true) {
            await categories_actions.categories_delete(category);
            dispatch(categories_actions.get_all_categories());
        }
    }
    const list_to_tree = (list) => {
        var map = {}, node, roots = [], i;
        //        console.log("list",list);
        for (i = 0; i < list.length; i += 1) {
            if (list[i].visible == 1) {
                map[list[i].id] = i; // initialize the map
                list[i].children = []; // initialize the children
            }
        }
               
        for (i = 0; i < list.length; i += 1) {
            if (list[i].visible == 1) {
                node = list[i];
                if(map[node.parent] === undefined){
                    roots.push(node);
                }else{
                    if (node.parent != 0 ) {// if you have dangling branches check that map[node.parentId] exists
                        if(map[node.parent] === undefined){
                            continue;
                        }
                        list[map[node.parent]].children.push(node);
                    } else {
                        roots.push(node);
                    }
                }
            }
        }

        setstate_categories_tree(roots);
    }
    useEffect(() => {
        if (categories.loading_categories == false) {
            list_to_tree(categories.categories);
        }
    }, [categories])

    const { required, handlers } = useTreeState({
        id: 'tree',
        data: categories_tree,
        defaultOpened: false,
        multipleSelect: false,
    })

    const renderNode = useCallback(({ node, onToggle, }) => (
        <div className="tree-node" key={node.data.title}>

            <div>
                <Categoria node={node} onToggle={onToggle} clickSubMenu={() => clickSubMenu('Editar', !openSubMenu.open, node.data)} clickDelete={() => handleClickDelete(node.data)} />
            </div>
        </div>
    ), [])

    return (
        <div>
            {openSubMenu.open ? <div className="absolute z-10 bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl"></div> : null}
            {categories_tree.length > 0 ?
                "" :
                <div className="mb-8">
                    <p className="text-sm ">Crea tu primera categoria</p>
                </div>
            }


            <div className="flex flex-row flex-wrap justify-end" onClick={() => clickSubMenu('Agregar', !openSubMenu.open)}>
                <div className=" m-2 flex items-center cursor-pointer">Agregar categorias<p className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</p></div>
            </div>

            <div>
                {categories.loading_categories == false ?
                    categories_tree.length > 0 ?
                        <Tree
                            {...required}
                            {...handlers}
                            draggable={false}
                            // gapMode={padding}
                            depthGap={10}
                            disableLines={true}
                            disableHorizontalLines={true}
                            disableVerticalLines={true}
                            verticalLineTopOffset={-17}
                            verticalLineOffset={5}
                            renderNode={renderNode}
                        />
                        : <p className="">No tienes categorias agregadas</p>
                    : <p className="">Cargando...</p>}
            </div>

            {openSubMenu.open ?
                <SubMenu>
                    <div>
                        <div className="min-w-full submenu">
                            <div className="w-1/4 bg-gray-100 h-80 rounded-xl" style={{ overflowY: "scroll" }}>
                                <div className=" flex justify-between items-center text-2xl p-4">
                                    <p>{openSubMenu.type}</p>
                                    <div className="flex" onClick={() => setOpenSubMenu(false)}>
                                        <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{ top: '-1px', marginLeft: '0.3rem' }} />
                                    </div>
                                </div>
                                {openSubMenu.type == 'Agregar' ?
                                    <AgregarCategoria onCloseSubMenu={setOpenSubMenu} /> :
                                    <EditarCategoria onCloseSubMenu={setOpenSubMenu} category={openSubMenu.category} />}
                            </div>
                        </div>
                    </div>
                </SubMenu>
                : null}
        </div>
    );
}


const Categoria = ({ node, onToggle, clickSubMenu, clickDelete }) => {
    const [OpenDropdown, setOpenDropdown] = useState(false);

    const onClickOutsidePopup = (ev) => {
        if (
            OpenDropdown &&
            document.querySelectorAll('.catselpopup').length &&
            (!ev.target.matches('.catselpopup')) 
            //&& (!ev.target.matches('.catselpopup *')) 
            
        ) {
            console.log('close menu');
            setOpenDropdown(false);
        }
        document.removeEventListener('click', onClickOutsidePopup);
    }

    /*const onClickOutsideListener = (e) => {
        document.removeEventListener("click", onClickOutsideListener);
        setOpenDropdown(false);
    }*/

    useEffect(() => {
        //console.log(OpenDropdown);
        if (OpenDropdown) {
            console.log('set click outside event');
            document.addEventListener('click', onClickOutsidePopup);
        }
    }, [OpenDropdown]);

    return (
        <div className={`h-20 mb-4 border border-blue-300 p-4 rounded-md ${node.data.parent == 0 ? 'bg-indigo-100' : 'bg-indigo-200'}`} style={{ border: '1px solid #797974' }}>

            <div className="absolute top-0 right-2 z-10">
                <span onClick={() => setOpenDropdown(!OpenDropdown)}>
                    {/* <img className="" src={three_dots} style={{ width: '1.5rem' }} /> */}
                    <FontAwesomeIcon icon={faEllipsisH} className="text-black cursor-pointer" />
                </span>
                {
                    OpenDropdown
                        ?
                        <div className="bg-white absolute top-5 right-2 p-2 catselpopup">
                            <p className="text-xs mb-2 hover:bg-gray-200 p-1 cursor-pointer" onClick={() => clickSubMenu()}>Editar</p>
                            <p className="text-xs hover:bg-gray-200 p-1 cursor-pointer" onClick={() => clickDelete(node.data)}>Eliminar</p>

                        </div>
                        :
                        ''
                }
            </div>

            <div className="w-full flex items-center">
                <div onClick={onToggle} className="w-3/4 cursor-pointer">
                    <p className="mb-1 text-md">{node.data.name}</p>
                    <p className="mb-1 text-xs">
                        {!!node.options.childrenCount && (
                            <span> Numero de subcategorias: {node.options.childrenCount}</span>
                        )}
                    </p>
                </div>


            </div>
        </div>
    );
}


export default Categorias;