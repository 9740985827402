import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Field, Form, } from 'formik';
import Select from 'react-dropdown-select';
import * as Yup from 'yup';
import { users_actions } from '../../../../store/actions/users';
import defaultuser from '../../../../assets/img/user/default.png';
import { min } from 'moment';
import { user as UserAdmin, nameFideicomisos } from '../../../../config/user_token';
import OptionesFideicomiso from '../../../OptionesFideicomiso';
const AgregarUser = ({ onCloseSubMenu }) => {
    const dispatch = useDispatch();
    const [fileUrl, setFileUrl] = useState(defaultuser);

    const validate_add = Yup.object().shape({
        firstname: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(50, 'Debe ser menor a 50 caracteres')
            .required('El campo es requerido'),
        lastname: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(50, 'Debe ser menor a 50 caracteres')
            .required('El campo es requerido'),
        username: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(10, 'Debe ser menor a 10 caracteres')
            .trim()
            .required('El campo es requerido'),
        /*cargo: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(50, 'Debe ser menor a 50 caracteres')
            .required('El campo es requerido'),*/
        /*centrotrabajo: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(50, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),*/
        /* fechainicio: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(16, 'Debe ser menor a 16 caracteres')
            .required('El campo es requerido'),
        fechavigencia: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(16, 'Debe ser menor a 16 caracteres')
            .required('El campo es requerido'), */
        /*fechaingreso: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(16, 'Debe ser menor a 16 caracteres')
            .required('El campo es requerido'),
        edad: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(16, 'Debe ser menor a 16 caracteres')
            .required('El campo es requerido'),
        sexo: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(16, 'Debe ser menor a 16 caracteres')
            .required('El campo es requerido'),*/
        password: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(16, 'Debe ser menor a 16 caracteres')
            .trim(),
        /*curp: Yup.string()
            .min(18, 'Debe ser de 18 caracter')
            //.max(18, 'Debe ser de 18 caracter')
            .trim()
            .required('El campo es requerido'),*/
        /*nationalocupation: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .trim()
            .required('El campo es requerido'),*/
        /* niveldesarrollo: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .trim()
            .required('El campo es requerido'), */
        email: Yup.string().email('El correo no es valido').required('El campo es requerido'),
        cohortid: Yup.array().min(1, 'Selecciona un grupo').required('El campo es requerido'),
        /* fideicomiso: Yup.number().min(1, "Debe ser mayor de 1").required('El campo es requerido'),
        comite: Yup.number().min(1, "Debe ser mayor de 1").required('El campo es requerido'),
        tipofideicomiso: Yup.string().required('El campo es requerido'), */
    });

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            username: '',
            //cargo: '',
            //area: '',
            //centrotrabajo: '',
            /* fechainicio: '',
            fechavigencia: '', */
            //fechaingreso: '',
            //edad: '',
            //sexo: '',
            email: '',
            password: '',
            file: '',
            //curp: '',
            //niveldesarrollo: '',
            //nationalocupation: 0,
            cohortid: [],
            fideicomiso: Array.isArray(UserAdmin.fideicomisoid) ? UserAdmin.fideicomisoid[0] : 0 ,
            comite: 0,
            tipofideicomiso: ''
        },
        validationSchema: validate_add,
        onSubmit: values => {
            console.log(values)
            handleSubmit(values)
        },
    });

    //Cohorts Options
    const cohorts = useSelector(state => state.cohorts);
    const options_cohorts = cohorts.cohorts.map((option) => ({
        ...option,
        label: option.name,
        value: option.id,
        cohortid: option.idnumber
    }));
    const [opciones, set_opciones] = useState([]);
    const [opciones_cohortes, set_opciones_cohortes] = useState([]);
    const optionsCohorts = async () => {
        let opcionesC = [];
        options_cohorts.map(cohort => {
            if (cohort.id !== 1 || cohort.name !== "webservice" || cohort.idnumber !== "webservice") {
                opcionesC.push(cohort);
            }
        });
        set_opciones(opcionesC);
        set_opciones_cohortes(opcionesC);
    }

    useEffect(() => {
        optionsCohorts();
    }, [])

    //Cohorts Selects
    const onChange = (values) => {
        formik.setFieldValue('cohortid', values);
        cohortes_seleccionados(values);
    };

    const cohortes_seleccionados = (cohortid) => {
        const selectes_cohortes = opciones.filter(cohort => !cohortid.includes(cohort));
        set_opciones_cohortes(selectes_cohortes);
    }

    const handleSubmit = async (user) => {
        await dispatch(users_actions.users_add(user));
        await onCloseSubMenu(false);
    }

    function processImage(event) {
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
    }

    return (
        <form className="formularios" onSubmit={formik.handleSubmit}>
            <div className="flex cursor-pointer items-center text-xl mb-7 relative group">
                <div className="w-1/5" >
                    <img className="h-16 w-16 rounded-md overflow-hidden" src={fileUrl} alt="" style={{ border: '1px solid #797974' }}></img>
                </div>
                <div className="flex items-center text-xl relative group ml-1">
                    <label className="mr-1  group-hover:text-opacity-50" htmlFor=""> Imagen de Perfil</label>
                    <p className="text-3xl text-red-500 group-hover:text-opacity-50">+</p>
                    <input onChange={e => {
                        processImage(e);
                        formik.setFieldValue('file', e.target.files[0])
                    }} name="file" type="file" className="absolute top-0 left-0 opacity-0" />
                    {/* <p className="text-red-600 text-sm">{formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</p> */}
                </div>
            </div>

            <div className="campo">
                <label className="text-md" htmlFor="">Nombre/s</label>
                <input onChange={formik.handleChange} type="text" name="firstname" placeholder="Nombre del usuario" value={formik.values.firstname} />
                <p className="text-red-600 text-sm">{formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</p>
            </div>

            <div className="campo">
                <label className="text-md" htmlFor="">Apellidos</label>
                <input onChange={formik.handleChange} type="text" name="lastname" placeholder="Apellido del usuario" value={formik.values.lastname} />
                <p className="text-red-600 text-sm">{formik.errors.lastname ? <div>{formik.errors.lastname}</div> : null}</p>
            </div>

            <div className="campo">
                <label className="text-md" htmlFor="">Username</label>
                <input onChange={formik.handleChange} placeholder="Username" type="text" name="username" value={formik.values.username} />
                <p className="text-red-600 text-sm">{formik.errors.username ? <div>{formik.errors.username}</div> : null}</p>
            </div>

            {/*<div className="campo">
                <label className="text-md" htmlFor="">Puesto</label>
                <input onChange={formik.handleChange} placeholder="puesto" type="text" name="cargo" value={formik.values.cargo} />
                <p className="text-red-600 text-sm">{formik.errors.cargo ? <div>{formik.errors.cargo}</div> : null}</p>
                </div>*/}

            {/*<div className="campo">
                <label className="text-md" htmlFor="">Centro de Trabajo</label>
                <input onChange={formik.handleChange} placeholder="centrotrabajo" type="text" name="centrotrabajo" value={formik.values.centrotrabajo} />
                <p className="text-red-600 text-sm">{formik.errors.centrotrabajo ? <div>{formik.errors.centrotrabajo}</div> : null}</p>
            </div>
            */}
            {/* <div className="campo">
                <label className="text-md" htmlFor="">Fideicomiso</label>
                <select onChange={formik.handleChange} className="custom-select" name="fideicomiso" id="fideicomiso">
                {
                    nameFideicomisos.map((fideicomiso,index)=>{
                                    return <OptionesFideicomiso fideicomisoid={index} fideicomiso = {fideicomiso} adminfideicomisosid = {UserAdmin.fideicomisoid}/>
                                })
                }    
                </select>
                <p className="text-red-600 text-sm">{formik.errors.fideicomiso ? <div>{formik.errors.fideicomiso}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Comite adscrito</label>
                <select onChange={formik.handleChange} className="custom-select" name="comite" id="comite">
                    <option value={0} selected disabled>Seleccione un comite</option>
                    <option value={1}>REPRESENTANTE DE SOCIO</option>
                    <option value={2}>ASISTENTE REGIONAL</option>
                    <option value={3}>COMITÉ TÉCNICO</option>
                    <option value={4}>CONSEJO DE VIGILANCIA</option>
                    <option value={5}>COMISIÓN DE AUDITORÍA</option>
                    <option value={6}>COMISIÓN DE ESTILO Y REDACCIÓN</option>
                    <option value={7}>PATRONATOS DE OBRAS</option>
                </select>
                <p className="text-red-600 text-sm">{formik.errors.comite ? <div>{formik.errors.comite}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Tipo de fideicomiso</label>
                <select onChange={formik.handleChange} className="custom-select" name="tipofideicomiso" id="tipofideicomiso">
                    {
                        UserAdmin.theme == "" ? 
                            <optgroup>
                            <option value={''} selected disabled>Seleccione un tipo</option>
                            <option value={'FIGLO'}>FIGLO</option>
                            <option value={'JUBICOM'}>JUBICOM</option>
                            </optgroup>
                        : <option value={UserAdmin.theme.toUpperCase()} selected>{UserAdmin.theme.toUpperCase()}</option>

                    }
                </select>
                <p className="text-red-600 text-sm">{formik.errors.tipofideicomiso ? <div>{formik.errors.tipofideicomiso}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Fecha de Inicio</label>
                <input onChange={formik.handleChange} type="date" name="fechainicio" placeholder="fechainicio" value={formik.values.fechainicio} />
                <p className="text-red-600 text-sm">{formik.errors.fechainicio ? <div>{formik.errors.fechainicio}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Fecha de Vigencia</label>
                <input onChange={formik.handleChange} type="date" name="fechavigencia" placeholder="fechavigencia" value={formik.values.fechavigencia} />
                <p className="text-red-600 text-sm">{formik.errors.fechavigencia ? <div>{formik.errors.fechavigencia}</div> : null}</p>
            </div> */}
            {/*<div className="campo">
                <label className="text-md" htmlFor="">Fecha de Ingreso</label>
                <input onChange={formik.handleChange} type="date" name="fechaingreso" placeholder="fechaingreso" value={formik.values.fechaingreso} />
                <p className="text-red-600 text-sm">{formik.errors.fechaingreso ? <div>{formik.errors.fechaingreso}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Edad</label>
                <input onChange={formik.handleChange} placeholder="edad" type="number" min="1" name="edad" value={formik.values.edad} />
                <p className="text-red-600 text-sm">{formik.errors.edad ? <div>{formik.errors.edad}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Sexo</label>
                <div>
                    <label className="text-md" htmlFor="">
                        <input onChange={formik.handleChange} type="radio" id="sexo" name="sexo" value="F" /> Femenino
                    </label>
                </div>
                <div>
                    <label>
                        <input onChange={formik.handleChange} type="radio" id="sexo" name="sexo" value="M" /> Masculino
                    </label>
                </div>
                <p className="text-red-600 text-sm">{formik.errors.sexo ? <div>{formik.errors.sexo}</div> : null}</p>
        </div>*/}
            <div className="campo">
                <label className="text-md" htmlFor="">Correo del usuario</label>
                <input onChange={formik.handleChange} type="text" name="email" placeholder="Correo del usuario" value={formik.values.email} />
                <p className="text-red-600 text-sm">{formik.errors.email ? <div>{formik.errors.email}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Contraseña</label>
                <input onChange={formik.handleChange} type="password" name="password" placeholder="Contraseña" value={formik.values.password} autoComplete={"new-password"} />
                <p className="text-red-600 text-sm">{formik.errors.password ? <div>{formik.errors.password}</div> : null}</p>
            </div>
{/*            <div className="campo">
                <label className="text-md" htmlFor="">Curp</label>
                <input onChange={formik.handleChange} type="text" name="curp" placeholder="Curp" value={formik.values.curp} />
                <p className="text-red-600 text-sm">{formik.errors.curp ? <div>{formik.errors.curp}</div> : null}</p>
    </div>*/}
            {/* <div className="campo">
                <label className="text-md" htmlFor="">Nivel de desarrollo</label>
                <input onChange={formik.handleChange} type="text" name="niveldesarrollo" placeholder="Nivel de desarrollo" value={formik.values.niveldesarrollo} />
                <p className="text-red-600 text-sm">{formik.errors.niveldesarrollo ? <div>{formik.errors.niveldesarrollo}</div> : null}</p>
            </div> */}
            {/*<div className='campo'>
                <label>Denominaciones de áreas y subáreas del Catálogo Nacional de Ocupaciones</label>
                <select class="custom-select" name="nationalocupation" id="id_nationalocupation" onChange={formik.handleChange} alue={formik.values.nationalocupation}>
                    <option value="0" selected disabled>Seleccione un área o sub área...</option>
                    <option value="1">Cultivo, crianza y aprovechamiento 01</option>
                    <option value="2">Agricultura y silvicultura 01.1</option>
                    <option value="3">Ganadería 01.2</option>
                    <option value="4">Pesca y acuacultura 01.3</option>
                    <option value="5">Extracción y suministro 02</option>
                    <option value="6">Exploración 02.1</option>
                    <option value="7">Extracción 02.2</option>
                    <option value="8">Refinación y beneficio 02.3</option>
                    <option value="9">Provisión de energía 02.4</option>
                    <option value="10">Provisión de agua 02.5</option>
                    <option value="11">Construcción 03</option>
                    <option value="12">Planeación y dirección de obras 03.1</option>
                    <option value="13">Edificación y urbanización 03.2</option>
                    <option value="14">Acabado 03.3</option>
                    <option value="15">Instalación y mantenimiento 03.4</option>
                    <option value="16">Tecnología 04</option>
                    <option value="17">Mecánica 04.1</option>
                    <option value="18">Electricidad 04.2</option>
                    <option value="19">Electrónica 04.3</option>
                    <option value="20">Informática 04.4</option>
                    <option value="21">Telecomunicaciones 04.5</option>
                    <option value="22">Procesos industriales 04.6</option>
                    <option value="23">Procesamiento y fabricación 05</option>
                    <option value="24">Minerales no metálicos 05.1</option>
                    <option value="25">Metales 05.2</option>
                    <option value="26">Alimentos y bebidas 05.3</option>
                    <option value="27">Textiles y prendas de vestir 05.4</option>
                    <option value="28">Materia orgánica 05.5</option>
                    <option value="29">Productos químicos 05.6</option>
                    <option value="30">Productos metálicos y de hule y plástico 05.7</option>
                    <option value="31">Productos eléctricos y electrónicos 05.8</option>
                    <option value="32">Productos impresos 05.9</option>
                    <option value="33">Transporte 06</option>
                    <option value="34">Ferroviario 06.1</option>
                    <option value="35">Autotransporte 06.2</option>
                    <option value="36">Aéreo 06.3</option>
                    <option value="37">Marítimo y fluvial 06.4</option>
                    <option value="38">Servicios de apoyo 06.5</option>
                    <option value="39">Provisión de bienes y servicios 07</option>
                    <option value="40">Comercio 07.1</option>
                    <option value="41">Alimentación y hospedaje 07.2</option>
                    <option value="42">Turismo 07.3</option>
                    <option value="43">Deporte y esparcimiento 07.4</option>
                    <option value="44">Servicios personales 07.5</option>
                    <option value="45">Reparación de artículos de uso doméstico y personal 07.6</option>
                    <option value="46">Limpieza 07.7</option>
                    <option value="47">Servicio postal y mensajería 07.8</option>
                    <option value="48">Gestión y soporte administrativo 08</option>
                    <option value="49">Bolsa, banca y seguros 08.1</option>
                    <option value="50">Administración 08.2</option>
                    <option value="51">Servicios legales 08.3</option>
                    <option value="52">Salud y protección social 09</option>
                    <option value="53">Servicios médicos 09.1</option>
                    <option value="54">Inscripción sanitaria y del medio ambiente 09.2</option>
                    <option value="55">Seguridad social 09.3</option>
                    <option value="56">Protección de bienes yo personas 09.4</option>
                    <option value="57">Comunicación 10</option>
                    <option value="58">Publicación 10.1</option>
                    <option value="59">Radio, cine, televisión y teatro 10.2</option>
                    <option value="60">Interpretación artística 10.3</option>
                    <option value="61">Traducción e interpretación lingüística 10.4</option>
                    <option value="62">Publicidad, propaganda y relaciones públicas 10.5</option>
                    <option value="63">Desarrollo y extensión del conocimiento 11</option>
                    <option value="64">Investigación  11.1</option>
                    <option value="65">Enseñanza  11.2</option>
                    <option value="66">Difusión cultural  11.3</option>
                </select>
                <p className="text-red-600 text-sm">{formik.errors.nationalocupation ? <div>{formik.errors.nationalocupation}</div> : null}</p>
        </div>*/}
            <div className="campo">
                <label className="mb-2" htmlFor="">Agregar a un grupo</label>

                <Select
                    multi
                    options={opciones_cohortes}
                    name="cohortid"
                    onChange={(value) => {
                        onChange(value)
                    }}
                />
                <p className="text-red-600 text-sm">{formik.errors.cohortid ? <div>{formik.errors.cohortid}</div> : null}</p>
            </div>
            <button type="submit" className="block mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  border border-blue-300 hover:bg-opacity-100"
            >Guardar</button>
        </form>
    );
}

const Optiones = ({fideicomisoid, fideicomiso, adminfideicomisosid})=>{
    if(Array.isArray(adminfideicomisosid)){
        let isOption = adminfideicomisosid.find(id=>{
            if(id == fideicomisoid){
                return true
            }
        })
        if(isOption)
            return <option value={fideicomisoid}>{fideicomiso}</option>

        return null;
    }
    return <option value={fideicomisoid}>{fideicomiso}</option>
}
export default AgregarUser;
