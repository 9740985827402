import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { forums_actions } from '../../store/actions/forums';



const ModalAddResponsePost = (props) => {
    const dispatch = useDispatch();

    const {forumId, postId, sizeReply, isPost} = props;
    const [showModal, setShowModal] = useState(false);


    console.log(props);
    const validate_add = Yup.object().shape({
        subject: Yup.string('Escribe el nombre del tema')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        description: Yup.string('Escribe la descripcion del tema')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(90, 'Debe ser menor a 90 caracteres')
            .required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: {
            id: postId,
            subject: '',
            description: '',
        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async post => {

        await forums_actions.respond_forum_post(post);
        dispatch(forums_actions.get_all_forums(forumId));
        setShowModal(false);
    }


    return (
        <>
            <button
                className="pr-2"
                type="button"
                onClick={() => setShowModal(true)}>
                    {isPost === true ? 'Discutir este topico' : `Discutir este topico (${sizeReply} replicas hasta el momento)`}
            </button>
            {showModal ?
                (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <form className="formularios" onSubmit={formik.handleSubmit}>
                                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                            <h3 className="text-3xl font-semibold">
                                                Responder al post
                                            </h3>
                                        </div>
                                        <div className="relative p-2 flex-auto">
                                            <div className="campo">
                                                <label htmlFor="">Asunto</label>
                                                <input onChange={formik.handleChange} type="text" name="subject" id="subject" placeholder="Nombre del tema" value={formik.values.subject} />
                                                <p className="text-red-600 text-sm">{formik.errors.subject ? <div>{formik.errors.subject}</div> : null}</p>
                                            </div>
                                            <div className="campo">
                                                <label htmlFor="">Mensaje</label>
                                                <textarea onChange={formik.handleChange} name="description" id="description" cols="20" rows="3" placeholder="Descripcion para el tema" value={formik.values.description}></textarea>
                                                <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                                            <button
                                                className="rounded-full btnForumCancel ease-linear transition-all duration-150 mr-3"
                                                type="reset"
                                                onClick={() => setShowModal(false)}>
                                                Cerrar
                                            </button>
                                            <button
                                                className="rounded-full btnForumSuccess ease-linear transition-all duration-150"
                                                type="submit">
                                                Crear
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
        </>
    );
}

export default ModalAddResponsePost;