import { categories_constants } from '../constants/categories';


const initialState = {
    categories: [],
    loading_categories: null,
    error_categories: null,

    get_categories: [],
    loading_get_categories: null,
    error_get_categories: null,

    loading_categories_edit: null,
    categories_edit: null,
    error_categories_edit: null,

    loading_categories_delete: null,
    category_delete: null,
    error_category_delete: null,

    loading_categories_create: null,
    categories_create: null,
    error_categories_cretate: null,
};

export function categories_reducers(state = initialState, action) {
  switch (action.type) {
    //------------GET All CATEGORIES--------------//
    case categories_constants.CATEGORIES_REQUEST:
        return {
            ...state,
            loading_categories: action.payload,
        };
    case categories_constants.CATEGORIES_SUCCESS:
        return {
            ...state,
            loading_categories: false,
            categories: action.payload,
            error_categories: null
        };
    case categories_constants.CATEGORIES_FAILURE:
        return {
            ...state,
            loading_categories: false,
            error_categories: action.payload
        };
//------------GET CATEGORIES--------------//
    case categories_constants.GET_CATEGORIES_REQUEST:
        return {
            ...state,
            loading_get_categories: action.payload,
        };
    case categories_constants.GET_CATEGORIES_SUCCESS:
        console.log("categorie",action.payload);
        return {
            ...state,
            loading_get_categories: false,
            get_categories: action.payload,
            error_get_categories: null
        };
    case categories_constants.GET_CATEGORIES_FAILURE:
        return {
            ...state,
            loading_get_categories: false,
            error_get_categories: action.payload
        };
    //-------------CREATE CATEGORY-----------------
    case categories_constants.CATEGORIES_REQUEST:
        return {
            ...state,
            loading_categories_create: action.payload,
        };
    case categories_constants.CATEGORIES_SUCCESS:
        return {
            ...state,
            loading_categories_create: false,
            categories_create: action.payload,
            error_categories_cretate: null
        };
    case categories_constants.CATEGORIES_FAILURE:
        return {
            ...state,
            loading_categories_create: false,
            error_categories_cretate: action.payload
        };
    // ------------- EDIT CATEGORY ----------
    case categories_constants.EDIT_CATEGORIES_REQUEST:
        return {
            ...state,
            loading_categories_edit: action.payload,
        };
    case categories_constants.EDIT_CATEGORIES_REQUEST:
        return {
            ...state,
            loading_categories_edit: false,
            categories_edit: action.payload,
            error_categories_edit: null
        };
    case categories_constants.EDIT_CATEGORIES_FAILURE:
        return {
            ...state,
            categories_edit: 0,
            loading_categories_edit: false,
            error_categories_edit: action.payload
        };

      //------------DELETE USER--------------//
      case categories_constants.CATEGORY_DELETE_REQUEST:
        return {
            ...state,
            loading_categories_delete: action.payload,
            category_delete: null,
            error_category_delete: null
        };
    case categories_constants.CATEGORY_DELETE_SUCCESS:
        return {
            ...state,
            loading_categories_delete: false,
            category_delete: action.payload,
            error_category_delete: false
        };
    case categories_constants.CATEGORY_DELETE_FAILURE:
        return {
            ...state,
            loading_categories_delete: false,
            category_delete: 0,
            error_category_delete: action.payload
        };
    default:
      return state
  }
}