import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import CSVReader from 'react-csv-reader'
import { CSVLink, CSVDownload } from "react-csv";

import agregar from '../../../assets/img/user/agregar.png'
import buscar_usuario from '../../../assets/img/user/buscar_usuario.svg'
import agredada_masiva from '../../../assets/img/user/agregada_masiva.png'
import { users_actions } from '../../../store/actions/users';
import Header from './Header';
import SubMenu from './SubMenu/SubMenu';
import AgregarUser from './Usuario/AgregarUser';
import EditarUser from './Usuario/EditarUser';
import EditUser from './Usuario/EditUser';
import User from './Usuario/User';
import UserCsv from './Usuario/UserCsv';
import { user as UserAdmin } from '../../../config/user_token';

import { nationalocupations } from '../../../store/constants/nationalocupations.js';
import Swal from 'sweetalert2';


const Usuarios = ({ onClose }) => {
    const dispatch = useDispatch();

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        user: {}
    });

    let user = {
        firstname: '',
        lastname: '',
        username: '',
        //employment: '',
        //city: '',
        /* fideicomisonum: '',
        comitenum: '',
        tipofideicomiso: '',
        fechainicio: '',
        fechavigencia: '', */
        //dateadmission: '',
        //age: '',
        //gender: '',
        password: '',
        email: '',
        //curp: '',
        //niveldesarrollo: '',
        //nationalocupation: ''
    }

    const [dataCsv, setDataCsv] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const closeModalCsv = async () => {
        await dispatch(users_actions.users_add_csv_reset());
        setShowModal(false)
        //onClose();
    }

    const users = useSelector(state => state.users);
    const [busquedaRes, guardarBusquedadRes] = useState([]);
    const [busquedaShow, guardarBusquedadShow] = useState(false);

    //useEffect(() => {
        //console.log(users.users.length);
        //guardarBusquedadRes(users.users)
    //}, [users]);

    const clickSubMenu = (type, open_param, user) => {

        //console.log(user);

        if (type=='Editar') {
            user.workplace = user.city;
        }

        setOpenSubMenu({
            type: type,
            open: open_param,
            user: user
        });
    }

    const handleChange = async e => {
        if (e.target.value.length >= 4) {
            let typeseach = document.querySelector("#typeseach").selectedOptions[0].value;
            let searchuser = e.target.value;
            await searchUser(searchuser, typeseach);
        }
    }
    const searchUser = async (search, typeseach) => {
        if (search !== '') {
            let result = await users_actions.get_user_field(search, typeseach);
            
            //Valida el admin tiene varios fideicomisos
            //if(Array.isArray(UserAdmin.fideicomisoid)){
                //let resultado = result.filter(user=> {
                    //let isuser = UserAdmin.fideicomisoid.find(id=>user.fideicomisonum == id);
                    //if(isuser){
                        //return user;
                    //}
                    //return null;
                    ////user.fideicomisonum == UserAdmin.fideicomisoid
                //})
                //guardarBusquedadRes(resultado);
            //}else{
                guardarBusquedadRes(result);
            //}
            guardarBusquedadShow(true)
        } else {
            guardarBusquedadRes([])
            guardarBusquedadShow(false)
        }
    }

    let dataUser = new Array();
    //if(Array.isArray(UserAdmin.fideicomisoid)){
       // UserAdmin.fideicomisoid.map((fideicomiso, index) =>{
            //dataUser.push([`userdemo_${UserAdmin.fideicomiso[index]}`, 'demo', 'demouser', '2do. VOCAL', '2do. VOCAL', 'Guadalajara',  fideicomiso, 7, 'FIGLO', '2022-01-01', '2022-04-01', '2022-01-01', 27, 'M', "12345pass", "userdemo@userdemo.com", "DEDU970901HVZMMS09", 'Lider', 2]);
        //})
    //}else{
        dataUser.push(["user", 'demo', 'demouser', "userdemo@userdemo.com", "12345pass"]);
    //}

    const csvData = [
        ['firstname', 'lastname', 'username', 'email', 'password'],
        ...dataUser
    ];
    return (
        <div>
            <UserCsv showModal={showModal} dataCsv={dataCsv} setShowModal={closeModalCsv} onCloseSubMenu={onClose} />

            <div className="bg-gray-100 w-1/4 rounded-xl h-80 scroll">
                {openSubMenu.open ? <div className="absolute bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl z-10"></div> : null}
                <Header title="Usuarios" onClose={onClose} />
                <div className="flex flex-row flex-wrap justify-end flex-col items-end">
                    <div onClick={() => clickSubMenu('Agregar', !openSubMenu.open)} className=" m-2 flex items-center cursor-pointer">
                        <p className="mr-1">Agregar Usuarios <span className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</span></p>
                    </div>
                    <div className=" m-2 flex items-center cursor-pointer relative">
                        <p className="mr-1">Agregar carga masiva <span className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</span></p>
                        <CSVReader
                            cssClass="absolute top-0 left-0 opacity-0"
                            parserOptions={{ header: true }}
                            onFileLoaded={(data, fileInfo) => {
                                try {
                                    const temp = [];
                                    data.forEach(element => {
                                        console.log(element);
                                        
                                        if (typeof (element.nomina)!='undefined' && element.nomina !== "") {
                                            element.username = element.nomina;
                                        } else if (typeof (element.NOMINA)!='undefined' && element.NOMINA !== "") {
                                            element.username = element.NOMINA;
                                        }

                                        if (typeof (element.firstname)!='undefined' && element.firstname !== "" && typeof (element.lastname)!='undefined' && element.lastname !== "" && typeof (element.email)!='undefined' && element.email !== ""
                                            && typeof (element.password)!='undefined' && element.password !== "" && typeof (element.username)!='undefined' && element.username !== "") {
                                            user.username = element.username.toLowerCase().replaceAll(' ', '_');
                                            user.email = element.email;
                                            user.firstname = element.firstname;
                                            user.lastname = element.lastname;
                                            //user.employment = element.employment;
                                            //user.city = element.city;
                                            
                                            if  ( ((typeof(element.city)=='undefined')||(element.city=='')) && (typeof(element.workplace)!='undefined') && (element.workplace!='') ) {
                                                //user.city = element.workplace;
                                            }
                                            
                                            //user.area = element.area;
                                            /* user.fideicomisonum = element.fideicomisonum;
                                            user.comitenum = element.comitenum;
                                            user.tipofideicomiso = element.tipofideicomiso;
                                            user.fechainicio = element.fechainicio;
                                            user.fechavigencia = element.fechavigencia; */

                                            //user.dateadmission = element.dateadmission;
                                            //user.dateadmission = users_actions.date_string_to_epoch(element.dateadmission);

                                            //user.age = element.age;
                                            //user.gender = element.gender;
                                            user.password = element.password;
                                            //user.curp = element.curp;
                                            //user.niveldesarrollo = element.niveldesarrollo;

                                            if (typeof(nationalocupations[element.nationalocupation])!=='undefined') {
                                                //user.nationalocupation = element.nationalocupation;
                                            } else {
                                                //user.nationalocupation = '1';
                                            }

                                            temp.push(user)
                                            user = {};
                                        }
                                    });

                                    setDataCsv(temp);
                                    setShowModal(true);
                                } catch (lll_error) {
                                    console.error(lll_error);
                                    Swal.fire(
                                        'Ocurrio un error al leer el archivo',
                                        lll_error.toString(),
                                        'error'
                                      );
                                }
                            }}
                        />
                    </div>
                    <div className=" m-2 flex items-center cursor-pointer relative p-1 rounded-md" style={{border: '1px solid #797974'}}>
                        <CSVLink data={csvData} filename={"demousers.csv"}>Descargar demo CSV</CSVLink>
                    </div>
                </div>
                {
                    <div>
                        <label for="search" className="block  text-sm">Buscar usuario</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-600 sm:text-sm">
                                    <FontAwesomeIcon icon={faSearch} className="cursor-pointer opacity-80 hover:opacity-100 " />
                                </span>
                            </div>
                            <input onChange={handleChange} type="text" name="search" id="search" className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 pr-12 sm:text-sm border-gray-300 rounded-md" style={{border: '1px solid #797974'}}/>
                            <div className="absolute inset-y-0 right-0 flex items-center" >
                                <label for="typeseach" className="sr-only">typeseach</label>
                                <select id="typeseach" name="typeseach" className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-600 sm:text-sm rounded-md">
                                    <option value="username">Nombre empleado</option>
                                </select>
                            </div>
                        </div>
                    </div>
                }

                {busquedaShow ?
                    <div>
                        {busquedaRes.length > 0 ?
                            <div className=" mt-5">
                                <p className="text-xs mb-1">Resultados: {busquedaRes.length}</p>
                                {busquedaRes.map(user => (
                                    <User key={user.id} user={user} clickSubMenu={() => clickSubMenu('Editar', !openSubMenu.open, user)} />
                                ))
                                }
                            </div>
                            : <p className="">No tienes usuarios agregados</p>}
                    </div>
                    : null}
                {openSubMenu.open ?
                    <SubMenu>
                        <div>
                            <div className="min-w-full  submenu">
                                <div className="w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                    <div className=" flex justify-between items-center text-2xl p-4">

                                        <p className="mr-1">{openSubMenu.type}</p>
                                        <div className="flex" onClick={() => setOpenSubMenu(false)}>
                                            <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '-1px', marginLeft: '0.3rem'}} />
                                        </div>
                                    </div>
                                    {openSubMenu.type === 'Agregar' ?
                                        <AgregarUser onCloseSubMenu={setOpenSubMenu} /> :
                                        <EditUser onCloseSubMenu={setOpenSubMenu} user={openSubMenu.user} searchUser={searchUser} />
                                    }
                                </div>
                            </div>
                        </div>
                    </SubMenu>
                    : null}
            </div>
        </div>

    );
}

export default Usuarios;
