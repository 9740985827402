import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import Select from 'react-dropdown-select';

import { courses_actions } from '../../../../../store/actions/courses';
import evaluacion from '../../../../../assets/img/cursos/evaluacion.png'
import { categories_actions } from '../../../../../store/actions/categories';
import { cohorts_actions } from '../../../../../store/actions/cohorts';
//import { user as UserAdmin } from '../../../../../config/user_token';

import { array } from 'yup/lib/locale';

const UserAdmin = ((localStorage.getItem('user')!==null)?(JSON.parse(localStorage.getItem('user'))):({userid:0,username:'',isadmin:false}));

const EditarCategoria = ({ category, onCloseSubMenu }) => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.categories);
    let userData = JSON.parse(localStorage.getItem('user'));
    const cohorts = useSelector(state => state.cohorts);
    const options_cohorts = cohorts.cohorts.map((option) => ({
        ...option,
        label: option.name,
        value: option.id,
        cohortid: option.idnumber
    }));

    const [cohorts_select, set_cohorts_select] = useState([]);

    let categoria = new Array();

    const orderCategori = function (categoriasuperior, path) {

        if (categoriasuperior.children.length > 0) {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
            categoriasuperior.children.map(categoriachildren => {
                if (categoriachildren.id != category.id) {
                    orderCategori(categoriachildren, `${path} / ${categoriachildren.name}`);
                }

            });
        } else {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
        }
    }

    categories.categories.map(categoriesraiz => {
        //if(userData.fideicomisoid === null){
            if (categoriesraiz.parent == 0 && categoriesraiz.hasOwnProperty('children') && category.id != categoriesraiz.id) {
                orderCategori(categoriesraiz, `${categoriesraiz.name}`);
            }
        //}else{
            //UserAdmin.fideicomiso.map(fideicomiso=>{
                //if (categoriesraiz.name.toLowerCase() == fideicomiso.toLowerCase() && categoriesraiz.hasOwnProperty('children')) {
                    //orderCategori(categoriesraiz, `${categoriesraiz.name}`);
                //}
            //});
        //}
    });

    const cohortes_seleccionados = () => {
        //const selectes_cohortes = options_cohorts.filter(cohort => cohort.id_category == category.id);
        const selectes_cohortes = options_cohorts;
        set_cohorts_select(selectes_cohortes);
    }
    const opciones = []
    options_cohorts.map(cohort => {
        if (cohort.id !== 1 || cohort.name !== "webservice" || cohort.idnumber !== "webservice") {
            //let isCohorteCategory = categories.categories.find(element => element.id === cohort.id_category)
            //if(isCohorteCategory){
                opciones.push(cohort);
            //}
        }
    });

    useEffect(() => {
        cohortes_seleccionados();
    }, []);

    const validate_edit = Yup.object().shape({
        id: Yup.string().required('El campo es requerido'),
        name: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        parent: Yup.number().required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: { ...category },
        validationSchema: validate_edit,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async (category) => {
        await categories_actions.categories_edit(category, cohorts_select);
        onCloseSubMenu(false);
        dispatch(categories_actions.get_all_categories());
        dispatch(cohorts_actions.get_all_cohorts());
    }
    const onChange = (values) => set_cohorts_select(values);
    return (
        <form className="formularios" onSubmit={formik.handleSubmit}>
            <div className="campo">
                <label className="mb-2" htmlFor="">Nombre de la categoria</label>
                <input type="text" onChange={formik.handleChange} name="name" placeholder="Nombre de la categoria" value={formik.values.name} />
                <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
            </div>

            <div className="campo">
                <label className="mb-2" htmlFor="">Categorias</label>
                <select onChange={formik.handleChange} name="parent" value={formik.values.parent}>
                    {category.parent == 0 ? <option className="bg-blue-600" selected value={0} >Superior</option> : ''}
                    {categories.loading_categories ? 'Cargando...' :
                        categories.categories.length > 0
                            ? categoria.map(item =>
                                item.parent == category.parent ? <option className="bg-blue-600" selected value={item.id} >{item.path}</option> :
                                    /*item.id != category.id ? */<option className="bg-blue-600" value={item.id} >{item.path}</option> /*: null*/
                            )
                            : null
                    }
                </select>
            </div>
            <div className="campo">
                <label className="mb-2" htmlFor="">Agregar grupo</label>
                <Select
                    multi
                    options={opciones}
                    values={[...cohorts_select]}
                    name="select"
                    // color="blue"
                    //dropdownGap={0}

                    onChange={(value) => onChange(value)}
                />
            </div>

            <button type="submit" className="block mx-auto text-center rounded-full bg-iacblack bg-opacity-100 px-4 py-2 w-48 text-white border border-blue-300  hover:text-blue-300 hover:bg-opacity-50">Guardar</button>

        </form>
    );
}

export default EditarCategoria;