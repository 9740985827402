
import { categories_constants } from '../constants/categories';
import { categories_service } from '../services/categories';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'

import { cohorts_service } from '../services/cohorts';

export const categories_actions = {
    get_all_categories,
    categories_edit,
    categories_delete,
    category_create,
    get_category
};

function get_all_categories() {
    return dispatch => {
        dispatch(request());

        categories_service.get_all_categories()
            .then(
                res => {
                    console.log(res);
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.categories));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: categories_constants.CATEGORIES_REQUEST, payload: true } }
    function success(param) { return { type: categories_constants.CATEGORIES_SUCCESS, payload: param } }
    function failure(error) { return { type: categories_constants.CATEGORIES_FAILURE, payload: error } }
}

function get_category(name) {
    return dispatch=>{
        dispatch(request());
        categories_service.get_categorie(name)
        .then(
            res=>{
                if(res.hasOwnProperty("error")){
                    dispatch(failure(res.error.message.toString()));
                }else{
                    console.log(res);
                    dispatch(success(res.categories));
                }
            },
            error=>{

            }
        )
        .catch(e => dispatch(failure(e.toString())))

    }



    function request() { return { type: categories_constants.GET_CATEGORIES_REQUEST, payload: true } }
    function success(param) { return { type: categories_constants.GET_CATEGORIES_SUCCESS, payload: param } }
    function failure(error) { return { type: categories_constants.GET_CATEGORIES_FAILURE, payload: error } }
}

async function categories_edit(category, selected_cohort) {

    await categories_service.categories_edit(category, selected_cohort)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString());
                    return Swal.fire(
                        'Categoria',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Categoria',
                        'Fue actualizada con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: categories_constants.EDIT_CATEGORIES_REQUEST, payload: true } }
    function success(param) { return { type: categories_constants.EDIT_CATEGORIES_REQUEST, payload: param } }
    function failure(error) { return { type: categories_constants.EDIT_CATEGORIES_REQUEST, payload: error } }
}


async function categories_delete(category) {

    await categories_service.categories_delete(category)
        .then(
            res => {
                if (res == 0) {
                    failure(res);
                    return Swal.fire(
                        'Categoria',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Categoria',
                        'Fue borrada con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: categories_constants.CATEGORY_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: categories_constants.CATEGORY_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: categories_constants.CATEGORY_DELETE_FAILURE, payload: error } }
}

async function category_create(category, cohorts_select) {

    await categories_service.categories_create(category, cohorts_select)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString());
                    return Swal.fire(
                        'Categoria',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Categoria',
                        'Fue agregada con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: categories_constants.CATEGORY_CREATE_REQUEST, payload: true } }
    function success(param) { return { type: categories_constants.CATEGORY_CREATE_SUCCESS, payload: param } }
    function failure(error) { return { type: categories_constants.CATEGORY_CREATE_FAILURE, payload: error } }
}
