import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { courses_actions } from '../../../../store/actions/courses';
import evaluacion from '../../../../assets/img/cursos/evaluacion.png'
import defaultImgiac from '../../../../assets/img/pocari.png';
import defaultImgdafi from '../../../../assets/img/dafi.png';
import SubMenu from '../SubMenu/SubMenu';
import Resources from './Resources';
import Access from './Access';
import { user as UserAdmin } from '../../../../config/user_token';

const EditarCurso = ({ course, onCloseSubMenu }) => {
    const dispatch = useDispatch();
    const [fileUrl, setFileUrl] = useState(defaultImgiac);
    const categories = useSelector(state => state.categories);
    let [categories_tree, setstate_categories_tree] = useState([]);
    let [startDate, setstartDate] = useState("");
    let [endDate, setendDate] = useState("");
    

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        course: {}
    });

    const [check_date, setCheckDate] = useState({
        start: false,
        end: false
    });


    let formatTime = 0;

    const convertUnixtoDate = (unixTime) => {

        const milliseconds = unixTime * 1000;
        const dateObject = new Date(milliseconds);
        const subDate = dateObject.toLocaleString("en-US").substring(0, 17);

        formatTime = moment(subDate).format("YYYY-MM-DD[T]HH:mm:ss");

        return formatTime;
    }

    useEffect(() => {
        if (moment(course.startdate).unix() <= 0) setCheckDate({ start: true, end: check_date.end })
        if (moment(course.enddate).unix() <= 0) setCheckDate({ start: check_date.start, end: true })
        if (categories.loading_categories === false) {
            list_to_tree(categories.categories);
        }
        if (typeof (course.startdate) == 'number') {
            let startdate = convertUnixtoDate(course.startdate)

            formik.setFieldValue('startdate', startdate)
            setstartDate(startdate);
        }

        if (typeof (course.enddate) == 'number' && course.enddate > 0) {
            let enddate = convertUnixtoDate(course.enddate)

            formik.setFieldValue('enddate', enddate)
            setendDate(enddate);

        }
        if (course.overviewfiles.length > 0) {
            setFileUrl(`${course.overviewfiles[0].fileurl}?token=${UserAdmin.token}`);
        }
    }, []);

    const list_to_tree = (list) => {
        var map = {}, node, roots = [], i;
        for (i = 0; i < list.length; i += 1) {
            if (list[i].visible == 1) {
                map[list[i].id] = i; // initialize the map
                list[i].children = []; // initialize the children

            }
        }

        for (i = 0; i < list.length; i += 1) {
            if (list[i].visible == 1) {
                node = list[i];
                if(map[node.parent] === undefined){
                    roots.push(node);
                }else{
                    if (node.parent != 0 ) {// if you have dangling branches check that map[node.parentId] exists
                        if(map[node.parent] === undefined){
                            continue;
                        }
                        list[map[node.parent]].children.push(node);
                    } else {
                        roots.push(node);
                    }
                }
            }
        }
        setstate_categories_tree(roots);
    }

    //Order Category
    let categoria = new Array();

    const orderCategori = function (categoriasuperior, path) {

        if (categoriasuperior.children.length > 0) {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
            categoriasuperior.children.map(categoriachildren => {
                orderCategori(categoriachildren, `${path} / ${categoriachildren.name}`);
            });
        } else {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
        }
    }

    categories.categories.map(categoriesraiz => {
        //if(UserAdmin.fideicomisoid === null){
            if (categoriesraiz.parent == 0 && categoriesraiz.hasOwnProperty('children')) {
                orderCategori(categoriesraiz, `${categoriesraiz.name}`);
            }
        //}else{
            //Se agrega Validacion para las categorias que pertenecen el administrador
            //UserAdmin.fideicomiso.map(fideicomiso=>{
                //if (categoriesraiz.name.toLowerCase() == fideicomiso.toLowerCase() && categoriesraiz.hasOwnProperty('children')) {
                    //orderCategori(categoriesraiz, `${categoriesraiz.name}`);
                //}
            //});
        //}
    });

    const validate_edit = Yup.object().shape({
        id: Yup.string().required('El campo es requerido'),
        fullname: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        categoryid: Yup.number().min(1, 'Selecciona una opción valida').required('El campo es requerido'),
        summary: Yup.string().min(5, 'Debe ser mayor a 5 caracter').required('El campo es requerido')
    });

    const formik = useFormik({
        initialValues: {
            ...course,
        },
        validationSchema: validate_edit,
        onSubmit: values => {
            course.enddate = endDate;
            course.startdate = startDate;
            handleSubmit(values)
        },
    });

    const handleSubmit = async course => {
        await dispatch(courses_actions.courses_edit(course));
        onCloseSubMenu(false)
    }
    function processImage(event) {
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
    }

    const clickSubMenu = (type, open_param, cohorte) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            cohorte: cohorte
        })
    }

    //Cambio de Fecha
    const chanegDate = (type, date) => {
        course.startdate = date;
    }

    return (
        <div>
            <div>
                {openSubMenu.open ? <div className="absolute z-10 bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl"></div> : null}
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="campo">
                        <div className="w-full border rounded-md">
                            <img className="h-40 rounded-md w-auto m-auto overflow-hidden" src={fileUrl} alt=""></img>
                        </div>
                    </div>
                    {UserAdmin.istutor ? <></> : <div className="flex cursor-pointer items-center text-xl mb-7 relative group">
                        <label className="mr-1  group-hover:text-opacity-50" htmlFor="">Agregar Imagen</label>
                        <p className="text-4xl text-red-600 group-hover:text-opacity-50">+</p>
                        <input className="rounded-md" onChange={e => {
                            processImage(e);
                            formik.setFieldValue('overviewfiles', e.target.files[0])
                        }} name="overviewfiles" type="file" className="absolute top-0 left-0 opacity-0" />
                        {/* <p className="text-red-600 text-sm">{formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</p> */}
                    </div>}
                    <div className="campo">
                        <label htmlFor="">Nombre del curso</label>
                        <input className="rounded-md" type="text" onChange={formik.handleChange} name="fullname" placeholder="Nombre del curso" value={formik.values.fullname} />
                        <p className="text-red-600 text-sm">{formik.errors.fullname ? <div>{formik.errors.fullname}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Descripción del curso</label>
                        <textarea className="rounded-md" onChange={formik.handleChange} type="text" name="summary" placeholder="Descripción del curso" value={formik.values.summary.replace(/<\/?[^>]+(>|$)/g, "")} />
                        <p className="text-red-600 text-sm">{formik.errors.summary ? <div>{formik.errors.summary}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Fecha de inicio</label>
                        <input className="rounded-md" onChange={
                            (e) => {
                                setstartDate(e.target.value);
                                formik.setFieldValue('startdate', e.target.value)
                            }
                        } type="datetime-local" name="startdate" value={startDate} />
                        <p className="text-red-600 text-sm">{
                            formik.errors.startdate ?
                                <div>{formik.errors.startdate}</div> :
                                null}
                        </p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Fecha de termino</label>
                        <input className="rounded-md" onChange={
                            (e) => {
                                setendDate(e.target.value);
                                formik.setFieldValue('enddate', e.target.value)
                            }
                        } type="datetime-local" name="enddate" value={endDate} />
                        <p className="text-red-600 text-sm">{
                            formik.errors.enddate ?
                                <div>{formik.errors.enddate}</div> :
                                null}
                        </p>
                    </div>
                    <div className="campo">
                        <label className="mb-2" htmlFor="">Categoria del curso</label>
                        <select className="rounded-md" onChange={formik.handleChange} name="categoryid" value={formik.values.categoryid}>
                            <option value={0} selected disabled>Selecciona una categoria</option>
                            {categories.loading_categories ? 'Cargando...' :
                                categories.categories.length > 0
                                    ? categoria.map(item =>
                                        <option className="bg-blue-600" value={item.id} select={item.id === course.categoryid ? "true" : 'false'}>{item.path}</option>
                                    )
                                    : null
                            }
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.categoryid ? <div>{formik.errors.categoryid}</div> : null}</p>
                    </div>
                    <div className="block-inline text-center mb-2">
                        <button style={UserAdmin.istutor?{ maxWidth: "90%" }:{ maxWidth: "45%" }} type="button"
                            className={`mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  
                                border border-blue-300 hover:bg-opacity-100 mr.1`}
                            onClick={() => clickSubMenu('Recursos', !openSubMenu.open)}>Recursos</button>
                        {UserAdmin.istutor ? <></> : <button style={{ maxWidth: "45%" }} type="button"
                            className={`mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  
                                border border-blue-300 hover:bg-opacity-100 ml-1`}
                            onClick={() => clickSubMenu('Accesos', !openSubMenu.open)}>Enrolamiento</button>}
                    </div>
                    {UserAdmin.istutor ? <></> : <div className="block-inline text-center ">
                        <button style={{ maxWidth: "45%" }} type="submit"
                            className={`mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48 
                            border border-blue-300 hover:bg-opacity-100`}>Guardar</button>
                    </div>}
                </form>
            </div>
            {openSubMenu.open ?
                <SubMenu>
                    <div>
                        <div className="min-w-full  submenu">
                            <div className="w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                <div className=" flex justify-between items-center text-2xl p-4">
                                    <p className="mr-1">{openSubMenu.type}</p>
                                    <div className="flex" onClick={() => setOpenSubMenu(false)}>
                                        <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '0px', marginLeft: '0.3rem'}}/>
                                    </div>
                                </div>
                                {
                                    openSubMenu.type === 'Recursos' ?
                                        <Resources onCloseSubMenu={clickSubMenu} course={course} /> :
                                        <Access onCloseSubMenu={clickSubMenu} course={course} />
                                }
                            </div>
                        </div>
                    </div>

                </SubMenu>
                : null}
        </div>
    );
}

export default EditarCurso;