import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faSearch, faChartLine, faFileAlt, faTrophy, faGift, faClipboardCheck,faDownload } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { dc3_actions } from '../../../../store/actions/dc3';
import { useDispatch ,useSelector } from 'react-redux';
import { user } from '../../../../config/user_token';

const ListUserDc3 = ({courseid})=>{

    const userList =  useSelector(state => state.dc3)
    console.log("userList",userList);
    console.log("validacion",userList.loading_userlistdc3);
    
    //const totalUsers =  userList.userlistdc3.users.length;
    return(
        !userList.loading_userlistdc3 ? 
			((typeof(userList.userlistdc3.users)!=='undefined') && (userList.userlistdc3.users.length)) ?
				<div>
					<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
						Total Usuarios Con DC3: {userList.userlistdc3.users.length}
					</label>
					<TableUser users={userList.userlistdc3.users}/>
				</div>
			: <div><p>No se encontraron usuarios con DC3</p></div>
        : <div><p>Cargando...</p></div>
    );
}
const TableUser = ({users})=>{
    return(
        <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
            <thead className='bg-gray-100 dark:bg-gray-700'>
                <tr>
                <th className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">'>Nombre</th>
                <th className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">'>Fecha</th>
                <th className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">'>PDF</th>
                </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700'>
                {
                    users.map(user=>{
                        let fecha = moment.unix(user.issuetime).format("lll");
                        return(<tr className='hover:bg-gray-100 dark:hover:bg-gray-700">'>
                            <td className='py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white'>{user.user}</td>
                            <td className='py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white'>{fecha }</td>
                            <td className='py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap text-emerald-400'>
                                    <a className="cursor-pointer" href={`data:application/pdf;base64,${user.certfile}`} download={`${user.user}_${fecha}`} target>
                                            <FontAwesomeIcon icon={faDownload} className='text-emerald-400'/>
                                    </a>
                            </td>
                          </tr>)
                    })
                }
            </tbody>
        </table>
    )
}
export default ListUserDc3;
