import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Header from './Header';
import SubMenu from './SubMenu/SubMenu';
import EditarCurso from './Curso/EditarCurso';
import Curso from './Curso/Curso';
import AgregarCurso from './Curso/AgregarCurso';

const Cursos = ({onClose}) => {
    const dispatch = useDispatch();

    const clickSubMenu = (type,open_param,course) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            course: course
        })
    }

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        course: {}
    });

    const courses = useSelector(state => state.courses);
    
    let user = JSON.parse(localStorage.getItem('user'));

    return ( 
        <div>
            <div className="bg-gray-100 w-1/4 rounded-xl h-80 scroll">
                { openSubMenu.open ? <div className="absolute bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl z-10"></div> : null }
                
                <Header title="Cursos" onClose={onClose}/>

                {user.istutor ? <></>:<div className="flex flex-row flex-wrap justify-end mb-2">
                    <div onClick={ () => clickSubMenu('Agregar',!openSubMenu.open) } className=" m-2 flex items-center cursor-pointer"> Agregar curso <p className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</p> </div>
                </div>}

                <div>
                    {courses.loading_courses == false ?
                        courses.courses.length > 0 ?
                            courses.courses.map(course => ( 
                                    <Curso key={course.id} course={course} clickSubMenu={ () => clickSubMenu('Editar',!openSubMenu.open,course) }/>
                                )) 
                        : <p className="">No tienes cursos agregados</p>
                    : <p className="">Cargando...</p> }
                </div>
                { openSubMenu.open ?   
                    <SubMenu>
                        <div>
                            <div className="min-w-full  submenu">
                                <div className="w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                    <div className=" flex justify-between items-center text-2xl p-4"> 
                                        <p className="mr-1">{ openSubMenu.type }</p> 
                                        <div className="flex" onClick={ () => setOpenSubMenu(false) }>
                                            <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '-1px', marginLeft: '0.3rem'}}/>
                                        </div>
                                    </div>
                                    { openSubMenu.type === 'Agregar' ? 
                                        <AgregarCurso onCloseSubMenu={ setOpenSubMenu }/> : 
                                        <EditarCurso onCloseSubMenu={ setOpenSubMenu } course={openSubMenu.course}/> }
                                </div>
                            </div>
                        </div>

                    </SubMenu> 
                :  null }
            </div>

        </div>
        
    );
}

export default Cursos;