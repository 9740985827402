import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import CSVReader from 'react-csv-reader'

import { courses_actions } from '../../../../store/actions/courses';
import evaluacion from '../../../../assets/img/cursos/evaluacion.png'
import { categories_actions } from '../../../../store/actions/categories';

import Accordion from './Accordion';
import api_iac from '../../../../config/instance_axios';
import defaultImgiac from '../../../../assets/img/iac.png';
import defaultImgdafi from '../../../../assets/img/dafi.png';
import { user as UserAdmin } from '../../../../config/user_token';

const AgregarCurso = ({ onCloseSubMenu }) => {
    const dispatch = useDispatch();
    const [fileUrl, setFileUrl] = useState();
    //const [fileUrl, setFileUrl] = useState(defaultImgdafi);
    let [categories_tree, setstate_categories_tree] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [urls, setUrl] = useState([]);
    const [pdfs, setPdf] = useState([]);
    const [videos, setVideo] = useState([]);
    const [scorms, setScorm] = useState([]);
    const [fecha, setFecha] = useState('');
    const [dataExamen, setdataExamen] = useState('');
    const categories = useSelector(state => state.categories);
    let userAdmin = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        //dispatch(categories_actions.get_all_categories())
        if (categories.loading_categories == false) {
            list_to_tree(categories.categories);
        }
        let toDay = new Date();
        let formatTimeStart = 0;

        formatTimeStart = moment(toDay).format("YYYY-MM-DD[T]HH:mm");
        formik.setFieldValue('startdate', formatTimeStart)
        setFecha(formatTimeStart);
    }, [])

    const list_to_tree = (list) => {
        var map = {}, node, roots = [], i;
        for (i = 0; i < list.length; i += 1) {
            if (list[i].visible == 1) {
                map[list[i].id] = i; // initialize the map
                list[i].children = []; // initialize the children

            }
        }

        for (i = 0; i < list.length; i += 1) {
            if (list[i].visible == 1) {
                node = list[i];
                if(map[node.parent] === undefined){
                    roots.push(node);
                }else{
                    if (node.parent != 0 ) {// if you have dangling branches check that map[node.parentId] exists
                        if(map[node.parent] === undefined){
                            continue;
                        }
                        list[map[node.parent]].children.push(node);
                    } else {
                        roots.push(node);
                    }
                }
            }
        }
        setstate_categories_tree(roots);
    }

    //Order Category
    let categoria = new Array();

    const orderCategori = function (categoriasuperior, path) {

        if (categoriasuperior.children.length > 0) {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
            categoriasuperior.children.map(categoriachildren => {
                orderCategori(categoriachildren, `${path} / ${categoriachildren.name}`);
            });
        } else {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
        }
    }

    categories.categories.map(categoriesraiz => {
        //if(userAdmin.fideicomisoid === null){
            if (categoriesraiz.parent == 0 && categoriesraiz.hasOwnProperty('children')) {
                orderCategori(categoriesraiz, `${categoriesraiz.name}`);
            }
        //}else{
            ////Se agrega Validacion para las categorias que pertenecen el administrador
            //UserAdmin.fideicomiso.map(fideicomiso=>{
                //console.log(fideicomiso);
                //if (categoriesraiz.name.toLowerCase() == fideicomiso.toLowerCase() && categoriesraiz.hasOwnProperty('children')) {
                    //orderCategori(categoriesraiz, `${categoriesraiz.name}`);
                //}
            //});
        //}
    });

    const validate_add = Yup.object().shape({
        fullname: Yup.string('Escribe el nombre de tu curso')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        categoryid: Yup.number().min(1, 'Selecciona una opción valida').required('El campo es requerido'),

        summary: Yup.string().min(5, 'Debe ser mayor a 5 caracter').required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: {
            fullname: '',
            categoryid: 0,
            summary: '',
            summaryformat: '2',
            password: '',
            overviewfiles: '',
            attempts: 0,
            grademethod: 0,
            enddate: ''
        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async (course) => {
        let files = videos.concat(pdfs)
        await dispatch(courses_actions.courses_create(course, dataExamen, urls, files, scorms))
        dispatch(courses_actions.get_all_courses());
        onCloseSubMenu(false)
    }

    function processImage(event) {
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
    }


    return (
        <>
            <form className="formularios" onSubmit={formik.handleSubmit}>
                <div className="campo">
                    <div className="w-full border rounded-md" style={{border: '1px solid #797974'}}>
                        <img className="h-40 rounded-md w-auto m-auto overflow-hidden" src={fileUrl} alt=""></img>
                    </div>
                </div>
                <div className="flex cursor-pointer items-center text-xl mb-7 relative group" >
                    <label className="mr-1  group-hover:text-opacity-50" htmlFor="">Agregar Imagen</label>
                    <p className="text-4xl text-red-600 group-hover:text-opacity-50">+</p>
                    <input className="rounded-md" onChange={e => {
                        processImage(e);
                        formik.setFieldValue('overviewfiles', e.target.files[0])
                    }} name="overviewfiles" type="file" className="absolute top-0 left-0 opacity-0" />
                    {/* <p className="text-red-600 text-sm">{formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</p> */}
                </div>
                <div className="campo">
                    <label htmlFor="">Nombre del curso</label>
                    <input className="rounded-md" onChange={formik.handleChange} type="text" name="fullname" placeholder="Nombre del curso" value={formik.values.fullname} />
                    <p className="text-red-600 text-sm">{formik.errors.fullname ? <div>{formik.errors.fullname}</div> : null}</p>
                </div>

                <div className="campo">
                    <label htmlFor="">Descripción del curso</label>
                    <textarea className="rounded-md" onChange={formik.handleChange} type="text" name="summary" placeholder="Descripción del curso" value={formik.values.summary} />
                    <p className="text-red-600 text-sm">{formik.errors.summary ? <div>{formik.errors.summary}</div> : null}</p>
                </div>

                <div className="campo">
                    <label htmlFor="">Fecha de inicio</label>
                    <input className="rounded-md" onChange={
                            (e) => {
                                setFecha(e.target.value);
                                formik.setFieldValue('startdate', e.target.value)
                            }
                        } type="datetime-local" name="startdate" value={fecha} />
                    <p className="text-red-600 text-sm">{
                        formik.errors.startdate ?
                            <div>{formik.errors.startdate}</div> :
                            null}
                    </p>
                </div>
                <div className="campo">
                    <label htmlFor="">Fecha de termino</label>
                    <input className="rounded-md" onChange={formik.handleChange} type="datetime-local" name="enddate" value={formik.values.enddate} />
                    <p className="text-red-600 text-sm">{
                        formik.errors.enddate ?
                            <div>{formik.errors.enddate}</div> :
                            null}
                    </p>
                </div>

                <div className="campo">
                    <label className="mb-2" htmlFor="">Categoria del curso</label>
                    <select className="rounded-md" onChange={formik.handleChange} name="categoryid" value={formik.values.categoryid}>
                        <option value={0} selected disabled>Selecciona una categoria</option>
                        {categories.loading_categories ? 'Cargando...' :
                            categories.categories.length > 0
                                ? categoria.map(item =>
                                    <option className="bg-blue-600" value={item.id} >{item.path}</option>
                                )
                                : null
                        }
                    </select>
                    <p className="text-red-600 text-sm">{formik.errors.categoryid ? <div>{formik.errors.categoryid}</div> : null}</p>
                </div>
                <button
                    className={`block mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 
                    w-48 hover:bg-opacity-100`}>
                    Guardar
                </button>

            </form>
        </>
    );
}

export default AgregarCurso;