import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es'
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { attempts_scorm_actions } from '../../../../store/actions/attemptScorm';
import { attempts_scorm_service } from '../../../../store/services/attemptScorm';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

const AttemptScorm = (props) => {
    const dispatch = useDispatch();

    const { scormid } = props.match.params;
    const [datAttemptScorm, setDatAttemptScorm] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loadedAttempts, setLoadedAttempts] = useState(false);

    //const user = JSON.parse(localStorage.getItem('user'));
    const user = useSelector(state => state.authentication);
    const data_attempts_scorm = useSelector(state => state.attempts_scorm.attempts_scorm);
    

    useEffect(()=> {
        //const getAttemptScorm = () => dispatch(attempts_scorm_actions.get_all_attempts_scorm(scormid));
        //getAttemptScorm(); 
        //dispatch(attempts_scorm_actions.get_all_attempts_scorm(scormid));
        console.log('Start...');
        attempts_scorm_service.get_all_attempts_scorm(scormid).then((res) => {
            if (typeof(res.attempts_scorm)!=='undefined') {
                console.log('Got attempts:');
                console.log(res.attempts_scorm);

                fill_attempts_table(res.attempts_scorm);
            }
        });

    }, []);

    //useEffect(() => {
        //const getAttemptScorm = () => dispatch(attempts_scorm_actions.get_all_attempts_scorm(scormid));
        //getAttemptScorm();
        //courses_seleccionados();
        //console.log('Loaded...');
    //}, [data_attempts_scorm]);



    /*const courses_seleccionados = () => {
        let intentosuser = [];
        let index = 1;
        data_attempts_scorm.map(useridatt => {
            useridatt.attempts.map((attem) => {

                let userintento = {
                    id: index++,
                    userid: useridatt.userid,
                    fullname: `${useridatt.userfirstname} ${useridatt.userlastname}`,
                    userusername: useridatt.userusername,
                    numberatt: attem.number,
                    reportedstatus: attem.reportedstatus,

                }
                intentosuser.push(userintento);
            });
        });
        console.log('Loaded thing');
        setDatAttemptScorm(intentosuser);
        setLoadedAttempts(true);
    }*/

    const fill_attempts_table = (a_attempts_data) => {
        let intentosuser = [];
        let index = 1;
        console.log(a_attempts_data.length);
        if ( (typeof(a_attempts_data.length)!=='undefined') && (a_attempts_data.length) ) {
            a_attempts_data.map(useridatt => {
                useridatt.attempts.map((attem) => {

                    let userintento = {
                        id: index++,
                        userid: useridatt.userid,
                        fullname: `${useridatt.userfirstname} ${useridatt.userlastname}`,
                        userusername: useridatt.userusername,
                        numberatt: attem.number,
                        reportedstatus: attem.reportedstatus,

                    }
                    intentosuser.push(userintento);
                });
            });
        }

        console.log('Loaded thing');
        setDatAttemptScorm(intentosuser);
        setLoadedAttempts(true);
    }

    //console.log(datAttemptScorm);


    const handleButtonClick = async (evt, row) => {
        //console.log(row);
        const result = await Swal.fire({
            title: '¿Esta seguro de eliminar el intento?',
            text: "Al borrar los intentos de un curso, se borrara también: certificados, puntos y registros de acceso al curso.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: "Cancelar"
          })
        //   .then((result) => {
        //     if (result.isConfirmed) {
        //       Swal.fire(
        //         'Deleted!',
        //         'Your file has been deleted.',
        //         'success'
        //       )
        //     }
        //   })

        if (result.isConfirmed) {
            console.log(scormid)
            await attempts_scorm_actions.delete_attempt_scorm(row, scormid);
            dispatch(attempts_scorm_actions.get_all_attempts_scorm(scormid));
        }
        
    };

    /* const handleChange = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []); */

    const columns = [
        /*{
            name: "Id",
            selector: "id",
            sortable: true
        },*/
        {
            name: "Nombre",
            selector: "fullname",
            sortable: true
        },
        {
            name: "Username",
            selector: "userusername",
            sortable: true,
        },
        {
            name: "Intento No.",
            selector: "numberatt",
            sortable: true
        },
        {
            name: "Estatus",
            selector: "reportedstatus",
            sortable: true,
        },
        {
            cell: (row) => <button className='text-center rounded-full bg-blue-500 text-white text-xs p-1.5' onClick={evt => handleButtonClick(evt, row)}>Eliminar intento</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    const data = [
        ...datAttemptScorm
    ];


    const tableData = {
        columns,
        data
    };



    return (
        <div className="container mx-auto px-4 pt-8 pb-32 h-screen overflow-y-scroll">
            <h3 className='text-4xl font-black text-blue-500 mr-5'>Quitar intentos Scorm</h3>
{((loadedAttempts)?(
            <DataTableExtensions
                export={false}
                print={false}
                {...tableData}>
                <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    striped
                    defaultSortField="userusername"
                    defaultSortAsc={false}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    highlightOnHover
                    paginationComponentOptions={paginationComponentOptions}
                    noDataComponent={<span>No se encontró ningún elemento</span>}
                />
            </DataTableExtensions>
):(<div class="text-center">Cargando intentos ... </div>))
}
        </div>
    );
}



export default AttemptScorm;
