export const categories_constants = {
    CATEGORIES_REQUEST: 'CATEGORIES_REQUEST',
    CATEGORIES_SUCCESS: 'CATEGORIES_SUCCESS',
    CATEGORIES_FAILURE: 'CATEGORIES_FAILURE',

    GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',

    EDIT_CATEGORIES_REQUEST: 'EDIT_CATEGORIES_REQUEST',
    EDIT_CATEGORIES_SUCCESS: 'EDIT_CATEGORIES_SUCCESS',
    EDIT_CATEGORIES_FAILURE: 'EDIT_CATEGORIES_FAILURE',

    CATEGORY_DELETE_REQUEST: 'CATEGORY_DELETE_REQUEST',
    CATEGORY_DELETE_SUCCESS: 'CATEGORY_DELETE_SUCCESS',
    CATEGORY_DELETE_FAILURE: 'CATEGORY_DELETE_FAILURE',

    CATEGORY_CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
    CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
    CATEGORY_CREATE_FAILURE: 'CATEGORY_CREATE_FAILURE',
    
}
